import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { ModalContent, ContentActions, ContentHeading, ContentDescription } from './styled';
import { modalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
import ButtonCustom from '@/shared/ui/ButtonCustom';
const handleClose = () => {
    modalStore.close();
};
const FriendAdded = observer(({ title, message, confirmLabel, width }) => (_jsxs(ModalContent, { width: width, children: [_jsx(ContentHeading, { children: _jsx(TextElement, { fontSize: 24, fontWeight: 600, component: 'h6', children: title }) }), _jsx(ContentDescription, { children: _jsx(TextElement, { fontSize: 16, children: message }) }), _jsx(ContentActions, { children: _jsx(ButtonCustom, { variant: 'contained', color: 'primary', height: 48, width: 162, onClick: handleClose, children: confirmLabel }) })] })));
export default memo(FriendAdded);
