import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { ListItemButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { AppBar, DesktopLink, LogoLink, SignInButton, Toolbar, ListItemDesktop, DesktopNavbar, BurgerMenu, NavbarMobile, CloseButton, NavbarMobileHeading, ListItemMobile, SocialsButton, SocialsItem, SocialsWrapper, MobileLink, } from './styled';
import { paths, socials } from './constants';
import SignInForm from '@/features/SignInForm';
import { LogoIcon, CloseIcon, BurgerMenuIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore } from '@/shared/model/modal';
import ListEnhanced from '@/shared/ui/ListEnhanced';
const handleOpenSignIn = () => {
    modalStore.open({
        content: _jsx(SignInForm, { title: 'Welcome Back!' }),
    });
};
const MainHeader = () => {
    const [active, setActive] = useState(false);
    const { pathname } = useLocation();
    const handleOpenNavbar = useCallback(() => {
        setActive(true);
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
    }, []);
    const handleCloseNavbar = useCallback(() => {
        setActive(false);
        document.body.style.position = '';
        document.body.style.width = '';
    }, []);
    return (_jsx(AppBar, { children: _jsxs(Toolbar, { children: [_jsx(DesktopNavbar, { children: _jsx(ListEnhanced, { row: true, children: paths.map(({ label, route }) => (_jsx(ListItemDesktop, { children: _jsx(ListItemButton, { component: DesktopLink, to: route, "aria-current": pathname === route ? 'page' : false, children: label }, label) }, label))) }) }), _jsxs(NavbarMobile, { className: active ? 'active' : undefined, children: [_jsxs(NavbarMobileHeading, { children: [_jsx(LogoLink, { to: ROUTES.home.path, onClick: handleCloseNavbar, children: _jsx(LogoIcon, { color: 'white', className: 'logo' }) }), _jsx(CloseButton, { color: 'white', "aria-label": 'Close navbar', onClick: handleCloseNavbar, children: _jsx(CloseIcon, { width: 16, height: 16 }) })] }), _jsx(ListEnhanced, { children: paths.map(({ label, route }) => (_jsx(ListItemMobile, { children: _jsx(ListItemButton, { component: MobileLink, to: route, onClick: handleCloseNavbar, "aria-current": pathname === route ? 'page' : false, children: label }, label) }, label))) }), _jsx(SocialsWrapper, { children: _jsx(ListEnhanced, { row: true, gap: '15px', children: socials.map(({ href, Icon }) => (_jsx(SocialsItem, { children: _jsx(SocialsButton, { component: 'a', href: href, rel: 'noopener noreferrer', target: '_blank', children: _jsx(Icon, {}) }) }, href))) }) })] }), _jsx(LogoLink, { to: ROUTES.home.path, children: _jsx(LogoIcon, { className: 'logo', color: 'primary' }) }), _jsx(SignInButton, { onClick: handleOpenSignIn, children: "Sign In" }), _jsx(BurgerMenu, { color: 'black', onClick: handleOpenNavbar, children: _jsx(BurgerMenuIcon, {}) })] }) }));
};
export default memo(MainHeader);
