import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { ModalContent } from './styled';
import { modalStore } from '@/shared/model/modal';
import ButtonCustom from '@/shared/ui/ButtonCustom';
import TextElement from '@/shared/ui/TextElement';
const handleClose = () => {
    modalStore.close();
};
const InteractionNotification = ({ title = 'Invitation sent!', message = 'Your invitation to join your table has been sent.', width, }) => (_jsxs(ModalContent, { width: width, children: [_jsx(TextElement, { variant: 'h6', fontSize: 24, fontWeight: 600, children: title }), _jsx(TextElement, { fontSize: 16, textAlign: 'center', children: message }), _jsx(ButtonCustom, { variant: 'contained', height: 48, width: 162, onClick: handleClose, children: "Continue" })] }));
export default memo(InteractionNotification);
