import styled from 'styled-components';
export const Wrapper = styled.div `
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ControlButtonsWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
