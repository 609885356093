import axiosInstance from '@/shared/api/http';
import { API } from '@/shared/constants/api';
import ServiceBuilder from '@/shared/lib/utils/service';
export const verifyAccessToken = async () => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.verifyAccessToken);
    return response;
});
export const getDetailsByRole = async (userId, role) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(!role ? API.userDetails : API.performerDetails, {
        params: { id: userId },
    });
    return response;
});
