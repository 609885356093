import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import loadFriends from '../../api';
import { userStore } from '@/shared/model/user';
import { friendStore } from '@/shared/model/friends';
import { headerStore } from '@/shared/model/header';
const useFetchFriends = () => {
    const { user } = userStore;
    const { id: userId } = user;
    const { chatListOpened, friendChatOpened } = headerStore;
    useEffect(() => {
        if (!userId)
            return;
        const fetchFriends = async () => {
            const response = await loadFriends(userId);
            if (response && !isAxiosError(response)) {
                friendStore.friends = response.data;
            }
        };
        fetchFriends();
    }, [userId, friendChatOpened, chatListOpened]);
};
export default useFetchFriends;
