import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { FieldAdornment, InputFieldStyled } from './styled';
import { ArrowDownIcon } from '@/shared/assets/icons';
import theme from '@/shared/lib/theme';
const InputFormField = ({ children, padding, Icon, InputProps, SelectProps, ...props }) => (_jsx(InputFieldStyled, { ...props, disableFieldset: true, SelectProps: {
        IconComponent: ArrowDownIcon,
        MenuProps: {
            PaperProps: {
                style: {
                    border: `1px solid ${theme.palette.primary.main}`,
                },
            },
        },
        ...SelectProps,
    }, "$padding": padding, InputProps: {
        ...InputProps,
        startAdornment: Icon ? (_jsx(FieldAdornment, { position: 'start', children: _jsx(Icon, {}) })) : (InputProps?.startAdornment),
    }, children: children }));
export default memo(InputFormField);
