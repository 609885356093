import { HubConnectionBuilder, LogLevel, } from '@microsoft/signalr';
import { makeAutoObservable, runInAction } from 'mobx';
import { http } from '@/shared/api/http';
import { friendStore } from '@/shared/model/friends';
const catchConnectionError = (callback) => {
    try {
        return callback();
    }
    catch (error) {
        if (!(error instanceof Error))
            return null;
        console.error(error.message);
        return null;
    }
};
class ConnectionStore {
    _interactionHubConnection = null;
    _tableChatHubConnection = null;
    _notificationHubConnection = null;
    _broadcastChannel = null;
    connectionOptions = {
        withCredentials: false,
        skipNegotiation: true,
        transport: 1, // WebSockets
    };
    constructor() {
        makeAutoObservable(this);
    }
    connectUserToRoom = async (userId, roomId) => {
        const connection = catchConnectionError(() => {
            if (this._interactionHubConnection)
                return null;
            return new HubConnectionBuilder()
                .withUrl(`${http.getBaseUrl()}/interactions?userId=${userId}&roomId=${roomId}&enterByInvite=${friendStore.enterFromInvite}`, this.connectionOptions)
                .withAutomaticReconnect()
                .configureLogging(process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Error)
                .build();
        });
        await connection?.start();
        runInAction(() => {
            this._interactionHubConnection = connection;
        });
        return this._interactionHubConnection;
    };
    connectUserToTableChat = (id, tableId) => {
        const connection = catchConnectionError(() => new HubConnectionBuilder()
            .withUrl(`${http.getBaseUrl()}/table/chat?userId=${id}&tableId=${tableId}`, this.connectionOptions)
            .withAutomaticReconnect()
            .configureLogging(process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Error)
            .build());
        this._tableChatHubConnection = connection;
        return this._tableChatHubConnection;
    };
    connectUserToGlobalNotifications = async (userId) => {
        const connection = catchConnectionError(() => new HubConnectionBuilder()
            .withUrl(`${http.getBaseUrl()}/notifications?userId=${userId}`, this.connectionOptions)
            .withAutomaticReconnect()
            .configureLogging(process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Error)
            .build());
        this._notificationHubConnection = connection;
        await this._notificationHubConnection?.start();
        return this._notificationHubConnection;
    };
    set interactionHubConnection(connection) {
        this._interactionHubConnection = connection;
    }
    get interactionHubConnection() {
        return this._interactionHubConnection;
    }
    set broadcastChannel(broadcastChannel) {
        this._broadcastChannel = broadcastChannel;
    }
    get broadcastChannel() {
        return this._broadcastChannel;
    }
    set tableChatHubConnection(connection) {
        this._tableChatHubConnection = connection;
    }
    get tableChatHubConnection() {
        return this._tableChatHubConnection;
    }
    set notificationHubConnection(connection) {
        this._notificationHubConnection = connection;
    }
    get notificationHubConnection() {
        return this._notificationHubConnection;
    }
    async closeRoomConnections() {
        await this._interactionHubConnection?.stop();
        await this._tableChatHubConnection?.stop();
        runInAction(() => {
            this._interactionHubConnection = null;
            this._tableChatHubConnection = null;
        });
    }
}
export const connectionStore = new ConnectionStore();
