import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, tooltipClasses } from '@mui/material';
import styled, { css } from 'styled-components';
export const CenterContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => css `
    gap: ${theme.spacing(2.5)};
  `}
`;
export const OneTableView = styled.div `
  width: 28px;
  height: 28px;
  border-radius: 2px;
  background-color: currentColor;
`;
export const NoMaxWidthTooltip = styled(({ className, ...props }) => (_jsx(Tooltip, { ...props, classes: { popper: className } })))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});
