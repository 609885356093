import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, memo } from 'react';
import { observer } from 'mobx-react-lite';
import { Content, SideMenu, ContentSection, SideMenuOption, SideMenuHeader, OptionIcon, OptionName, CloseIconButton, } from './styled';
import ProfileForm from './model/ProfileForm';
import DeleteForm from './model/DeleteForm';
import SecurityForm from './model/SecurityForm';
import SoundForm from './model/SoundForm';
import AdministrationForm from './model/AdministrationForm';
import { CloseIcon, KeyIcon, MusicIcon, ProfileCircleIcon, VisibilityIcon, } from '@/shared/assets/icons';
import { modalStore } from '@/shared/model/modal';
import { userStore } from '@/shared/model/user';
const sideMenuButtons = [
    { icon: _jsx(ProfileCircleIcon, {}), name: 'Profile & Preferences', visibility: true },
    { icon: _jsx(KeyIcon, {}), name: 'Sign in & Security', visibility: true },
    { icon: _jsx(MusicIcon, {}), name: 'Sound', visibility: true },
    { icon: _jsx(VisibilityIcon, {}), name: 'Administration', visibility: true, isAdminOnly: true },
    { icon: null, name: 'Delete account', visibility: false },
];
const handleClose = () => {
    modalStore.close();
};
const Profile = observer(({ security }) => {
    const { user } = userStore;
    const [selectedPage, setSelectedPage] = useState(security ? 'Sign in & Security' : 'Profile & Preferences');
    const handleChangePage = useCallback((page) => () => {
        setSelectedPage(page);
    }, []);
    const [disableClose, setDisableClose] = useState(false);
    const handleEnableClose = useCallback(() => {
        setDisableClose(false);
        modalStore.loadingState = false;
    }, []);
    const handleDisableClose = useCallback(() => {
        setDisableClose(true);
        modalStore.loadingState = true;
    }, []);
    return (_jsxs(Content, { children: [_jsx(CloseIconButton, { disabled: disableClose, onClick: handleClose, children: _jsx(CloseIcon, {}) }), _jsxs(SideMenu, { children: [_jsx(SideMenuHeader, { children: "Settings" }), sideMenuButtons.map((option) => {
                        const selected = selectedPage === option.name ||
                            (selectedPage === 'Delete account' && option.name === 'Sign in & Security');
                        return option.visibility &&
                            (!option.isAdminOnly || (option.isAdminOnly && user.isAdmin)) ? (_jsxs(SideMenuOption, { "aria-disabled": disableClose, onClick: disableClose ? undefined : handleChangePage(option.name), selected: selected, children: [_jsx(OptionIcon, { children: option.icon }), _jsx(OptionName, { children: option.name })] }, option.name)) : null;
                    })] }), _jsxs(ContentSection, { children: [selectedPage === 'Profile & Preferences' ? (_jsx(ProfileForm, { onDisableClose: handleDisableClose, onEnableClose: handleEnableClose })) : null, selectedPage === 'Sign in & Security' ? (_jsx(SecurityForm, { setSelectedPage: setSelectedPage, onDisableClose: handleDisableClose, onEnableClose: handleEnableClose })) : null, selectedPage === 'Sound' ? _jsx(SoundForm, {}) : null, selectedPage === 'Delete account' ? (_jsx(DeleteForm, { setSelectedPage: setSelectedPage, onDisableClose: handleDisableClose, onEnableClose: handleEnableClose })) : null, selectedPage === 'Administration' ? _jsx(AdministrationForm, {}) : null] })] }));
});
export default memo(Profile);
