import { IconButton, TextField } from '@mui/material';
import styled, { css } from 'styled-components';
export const SearchBarStyled = styled(TextField) `
  max-height: 40px;

  &:focus-within {
    & .MuiInputAdornment-positionStart {
      display: none;
    }
  }

  & .MuiFormHelperText-root {
    ${({ theme, error }) => css `
      font-size: ${theme.util.pxToRem(14)};
      color: ${theme.palette[error ? 'error' : 'inactive'].main};
    `}
  }

  & .MuiSvgIcon-root {
    color: '#8F8F8F';
  }

  & .MuiInputBase-root {
    border-radius: 8px;
  }

  & .MuiInputBase-input {
    padding: 8px;

    &::placeholder {
      ${({ theme }) => css `
        font-size: ${theme.util.pxToRem(16)};
      `}
    }
  }
`;
export const CloseButton = styled(IconButton) `
  position: absolute;
  right: 0;

  & .MuiSvgIcon-root {
    height: 10px;
    width: 10px;
  }
`;
