import { useEffect } from 'react';
import _debounce from 'lodash.debounce';
import useEvent from './useEvent';
const useWindowEvent = (type, callback, wait) => {
    const savedCallback = useEvent(callback);
    useEffect(() => {
        const call = (event) => savedCallback(event);
        const handleEvent = (wait === undefined ? call : _debounce(call, wait));
        window.addEventListener(type, handleEvent);
        return () => {
            window.removeEventListener(type, handleEvent);
        };
    }, [savedCallback, type, wait]);
};
export default useWindowEvent;
