import styled, { css } from 'styled-components';
export const ModalContent = styled.div `
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 430px;
  max-width: 490px;

  ${({ theme }) => css `
    ${theme.breakpoints.down('sm')} {
      max-width: 90vw;
    }
  `}
`;
export const CloseButtonWrapper = styled.div `
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
`;
export const NotificationContent = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  padding-top: 0;
  gap: 14px;
`;
