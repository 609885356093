import styled, { css } from 'styled-components';
import { List, ListItemButton } from '@mui/material';
export const UnreadIcon = styled.div `
  width: 14px;
  height: 14px;

  border-radius: 99999px;

  background-color: #a041ff;
`;
export const FriendActions = styled.div `
  position: absolute;
  right: 10px;
  height: 100%;
  width: fit-content;
  display: none;
  gap: 3px;
  align-items: center;
  justify-content: center;
`;
export const FriendContainer = styled.li `
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f3f2fa;

    & ${FriendActions} {
      display: flex;
    }

    & ${UnreadIcon} {
      display: none;
    }
  }
`;
export const UserContainer = styled.div `
  display: flex;
  position: relative;
`;
export const UserName = styled.p `
  margin: 0;
  padding: 0;
  ${({ theme, color }) => css `
    color: ${theme.palette[color].main};
    font-size: ${theme.util.pxToRem(16)};
    font-weight: 400;
  `}
`;
export const UserRoom = styled.p `
  max-width: 200px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => css `
    color: ${theme.palette.inactive.main};
    font-size: ${theme.util.pxToRem(14)};
    font-weight: 400;
  `}
`;
export const OnClickWrapper = styled.div `
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const OnlineStatus = styled.span `
  position: absolute;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  border: 3px solid white;
  height: 15px;
  width: 15px;
  ${({ theme, status }) => css `
    background-color: ${theme.palette[status].main};
  `}
`;
export const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
`;
export const FriendsActionsList = styled(List) `
  ${({ theme }) => css `
    & > .MuiDivider-root {
      margin: ${theme.spacing(6.5, 0)};
    }
  `}

  & .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export const ActionsList = styled(List) `
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => css `
    gap: ${theme.spacing(7)};
  `}
`;
export const ActionButton = styled(ListItemButton).attrs(() => ({
    component: 'button',
})) `
  ${({ theme, color = 'black' }) => css `
    color: ${theme.palette[color].main};
    gap: ${theme.spacing(2)};
    font-size: ${theme.util.pxToRem(14)};
  `}
  white-space: nowrap;
  padding: 8px;
`;
