import { Paper, List } from '@mui/material';
import styled, { css } from 'styled-components';
export const RequestsContainer = styled.section `
  display: flex;
  width: 100%;
  ${({ theme }) => css `
    height: calc(100dvh - ${theme.values.header.room.height});
  `}
  flex-direction: column;
`;
export const Heading = styled(Paper) `
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 55px;
  border-radius: 0;
  padding: 0 10px;
  z-index: 1;
`;
export const RequestAction = styled.div `
  position: absolute;
  right: 10px;
  display: flex;
  height: 100%;
  width: fit-content;
  align-items: center;
  justify-content: center;
`;
export const Request = styled.li `
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  gap: 10px;
`;
export const RequestsList = styled(List) `
  flex: auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  padding-right: 8px;
  overflow-y: auto;
  border-radius: 0;
  position: relative;
  margin-right: -5px;

  max-height: 90dvh;

  @media screen and (max-height: 900px) {
    max-height: 85dvh;
  }

  @media screen and (max-height: 800px) {
    max-height: 80dvh;
  }

  &::-webkit-scrollbar {
    width: 5px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background: #a2a2bf;
    box-shadow: 0px 20px 70px rgba(55, 69, 87, 0.3);
    border-right: none;
    border-left: none;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  ${({ $isLobby }) => css `
    ${$isLobby
    ? css `
          &::-webkit-scrollbar-thumb {
            ${({ theme }) => css `
              background: ${theme.palette.primary.main};
            `}
          }

          &::-webkit-scrollbar-track {
            background: #e2e2e2;
          }
        `
    : css `
          &::-webkit-scrollbar-thumb {
            background: #a2a2bf;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }
        `}
  `}
`;
