import { useEffect } from 'react';
import useEvent from './useEvent';
import { connectionStore } from '@/shared/model/connection';
const useNotificationHubReceive = (hubMethod, callback) => {
    const savedCallback = useEvent(callback);
    const { notificationHubConnection } = connectionStore;
    useEffect(() => {
        notificationHubConnection?.on(hubMethod, (hubMessage) => {
            savedCallback(hubMessage);
        });
    }, [hubMethod, notificationHubConnection, savedCallback]);
};
export default useNotificationHubReceive;
