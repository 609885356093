import imageCompression from 'browser-image-compression';
import { useCallback, useEffect, useRef, useState } from 'react';
export const toBytes = (mb) => mb * 1024 * 1024;
export const toMBs = (bytes) => bytes / 1024 / 1024;
const usePictureUploader = (onMaxSize, { maxSizeMB = 10, defaultFileData = null } = {}) => {
    const [fileData, setFileData] = useState(defaultFileData);
    const [key, setKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const savedCallback = useRef(null);
    useEffect(() => {
        savedCallback.current = onMaxSize;
    }, [onMaxSize]);
    const handleFileReset = useCallback(() => {
        setFileData(null);
        setKey((prevKey) => prevKey + 1);
    }, []);
    const handleFileChange = useCallback(async (e) => {
        if (!e.target.files) {
            return;
        }
        const maxSize = toBytes(maxSizeMB);
        const image = e.target.files[0];
        if (image.size >= maxSize) {
            savedCallback.current?.(maxSizeMB);
            return;
        }
        setLoading(true);
        setFileData(null);
        const sizePercentage = (image.size / maxSize) * 100;
        const maxClamp = 60;
        const minClamp = 0;
        const compressionPercentageUnclamped = maxClamp - sizePercentage / 4;
        const compressionPercentage = Math.min(Math.max(compressionPercentageUnclamped, minClamp), maxClamp) / 100;
        const options = {
            maxSizeMB,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            maxIteration: 30,
            quality: compressionPercentage,
        };
        const compressedImage = await imageCompression(image, options);
        setLoading(false);
        const reader = new FileReader();
        reader.readAsDataURL(compressedImage);
        reader.onload = (event) => {
            const data = event.target?.result?.toString();
            const splitData = data?.split(';base64,');
            setFileData({
                format: splitData?.[0],
                data,
            });
        };
    }, [maxSizeMB]);
    return { key, fileData, handleFileChange, handleFileReset, setFileData, loading };
};
export default usePictureUploader;
