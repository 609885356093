import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Grid } from '@mui/material';
import { socials } from '../../constants';
import { Column, SocialsItem, SocialsButton } from '../../styled';
import { BRAND_NAME } from '@/shared/constants/common';
import ListEnhanced from '@/shared/ui/ListEnhanced';
import TextElement from '@/shared/ui/TextElement';
const SocialsGridItem = () => (_jsx(Grid, { item: true, xs: 12, sm: 2, md: 3, lg: true, children: _jsxs(Column, { children: [_jsx(TextElement, { fontWeight: 600, children: `Follow ${BRAND_NAME}` }), _jsx(ListEnhanced, { row: true, gap: '5px', marginLeft: '-7px', children: socials.map(({ href, Icon }) => (_jsx(SocialsItem, { children: _jsx(SocialsButton, { component: 'a', href: href, rel: 'noopener noreferrer', target: '_blank', children: _jsx(Icon, {}) }) }, href))) })] }) }));
export default memo(SocialsGridItem);
