import styled, { css } from 'styled-components';
import { Button, IconButton } from '@mui/material';
import Svg from '@/shared/ui/Svg';
export const Content = styled.section `
  padding: 0;
  position: relative;

  display: flex;

  width: 905px;
  height: 565px;
  border-radius: 8px;
`;
export const SideMenu = styled.aside `
  width: 255px;
  height: 100%;

  background: #f9f5ff;
  mix-blend-mode: normal;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;
export const ContentSection = styled.section `
  width: 650px;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  background: #ffffff;
  box-shadow: 0 20px 50px rgba(55, 69, 87, 0.1);
`;
export const SideMenuHeader = styled.p `
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;

  color: #333238;
`;
export const SideMenuOption = styled.div `
  ${({ theme, selected }) => selected
    ? css `
          color: ${theme.palette.primary.main};
        `
    : css `
          color: #333238;
        `}

  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  align-items: center;
  height: 26px;
  cursor: pointer;
  margin-left: 24px;
  margin-bottom: 30px;
`;
export const OptionIcon = styled.div `
  ${Svg} {
    width: 24px;
    height: 24px;
  }
`;
export const OptionName = styled.p `
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
`;
export const CloseIconButton = styled(IconButton) `
  position: absolute;
  right: 10px;
  top: 10px;

  z-index: 100;
`;
export const ResetButton = styled(Button) `
  width: 142px;
  height: 48px;
  border-radius: 4px;
  color: #6945fa;
  border: 1px solid #6945fa;
`;
