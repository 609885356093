import { makeAutoObservable } from 'mobx';
class RoomPopupsStore {
    _popup = null;
    _searchInBar = false;
    _searching = false;
    _findMyself = false;
    _findUser = null;
    _seatWasFound = false;
    _searchAllowed = false;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    set findMyself(findMyself) {
        if (findMyself) {
            this._searchAllowed = true;
        }
        else {
            this._searchAllowed = false;
        }
        if (this._findMyself !== findMyself) {
            this._findMyself = findMyself;
        }
    }
    get findMyself() {
        return this._findMyself;
    }
    set searching(searching) {
        this._searching = searching;
    }
    get searching() {
        return this._searching;
    }
    set findUser(findUser) {
        this._findUser = findUser;
    }
    get findUser() {
        return this._findUser;
    }
    get searchAllowed() {
        return this._searchAllowed;
    }
    set searchAllowed(allowed) {
        this._searchAllowed = allowed;
    }
    set seatWasFound(seatWasFound) {
        this._seatWasFound = seatWasFound;
    }
    get seatWasFound() {
        return this._seatWasFound;
    }
    set searchInBar(search) {
        this._searchInBar = search;
    }
    get searchInBar() {
        return this._searchInBar;
    }
    set popup(popup) {
        this._popup = popup;
    }
    get popup() {
        return this._popup;
    }
    clear = () => {
        this._popup = null;
    };
}
const roomPopupsStore = new RoomPopupsStore();
export default roomPopupsStore;
