import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CardActions, IconButton, Typography } from '@mui/material';
import { forwardRef, memo, useCallback, useEffect } from 'react';
import { SnackbarContent, useSnackbar } from 'notistack';
import { CardStyled, SnackbarMessageContainer, UserPicture, CardContent, CardIcon } from './styled';
import { CloseIcon } from '@/shared/assets/icons';
import { snackbarNotificationSound } from '@/shared/assets/sounds';
const InnerNotificationSnackbar = forwardRef(({ id, src, icon, alt, message, hint, color, type, withoutAudio, onClick, customContent }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const handleClose = useCallback((e) => {
        e.stopPropagation();
        closeSnackbar(id);
    }, [closeSnackbar, id]);
    const handleClick = useCallback((e) => {
        if (!onClick) {
            return;
        }
        handleClose(e);
        onClick();
    }, [handleClose, onClick]);
    useEffect(() => {
        if (withoutAudio) {
            return;
        }
        if (type === 'joinedRoom') {
            // new Audio(hasJoinedRoomSound).play();
        }
        else {
            new Audio(snackbarNotificationSound).play();
        }
    }, [type, withoutAudio]);
    return (_jsx(SnackbarContent, { ref: ref, children: customContent ?? (_jsxs(CardStyled, { color: color, onClick: handleClick, children: [_jsxs(CardContent, { children: [icon && _jsx(CardIcon, { children: icon }), (src || alt) && _jsx(UserPicture, { src: src, alt: alt, variant: 'rounded' }), _jsxs(SnackbarMessageContainer, { children: [_jsx(Typography, { noWrap: true, fontWeight: 600, fontSize: '16px', children: message }), _jsx(Typography, { noWrap: true, fontWeight: 300, fontStyle: 'italic', fontSize: '14px', children: hint })] })] }), _jsx(CardActions, { children: _jsx(IconButton, { size: 'small', "aria-label": 'Close', color: 'black', onClick: handleClose, children: _jsx(CloseIcon, {}) }) })] })) }));
});
export const NotificationSnackbar = memo(InnerNotificationSnackbar);
