import { IconButton, Paper } from '@mui/material';
import styled, { css } from 'styled-components';
export const Content = styled(Paper) `
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 32px;
  width: 100vw;
  max-width: 520px;
  height: fit-content;
  position: relative;

  ${({ theme }) => css `
    ${theme.breakpoints.down('sm')} {
      max-width: 90vw;
    }
  `}
`;
export const ActionsForm = styled.form `
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;
export const CloseButton = styled(IconButton) `
  position: absolute;
  top: 20px;
  right: 20px;

  & .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }
`;
export const RequestSent = styled(Paper) `
  position: absolute;
  bottom: -165px;
  left: 0;
  height: 150px;
  padding: 32px;
  width: 100vw;
  max-width: 520px;

  ${({ theme }) => css `
    color: ${theme.palette.black.main};
  `}

  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  justify-content: center;
  align-items: center;

  &::after {
    position: absolute;
    content: '';
    top: -9px;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    ${({ theme }) => css `
      border-bottom: 10px solid ${theme.palette.common.white};
    `}
  }
`;
