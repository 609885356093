import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { Component } from 'react';
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }
    static getDerivedStateFromError() {
        return { hasError: true, error: null, errorInfo: null };
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: false, error, errorInfo });
        console.error('Uncaught error:', error, errorInfo);
    }
    render() {
        const { hasError, error, errorInfo } = this.state;
        const { children } = this.props;
        return hasError ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: 'h2', component: 'h1', textAlign: 'center', color: 'black', children: "Sorry, an error has occurred" }), error?.message ? (_jsx(Typography, { variant: 'h6', textAlign: 'center', color: 'black', children: error.message })) : null, errorInfo?.componentStack ? (_jsx(Typography, { textAlign: 'center', color: 'black', children: errorInfo.componentStack })) : null] })) : (children);
    }
}
