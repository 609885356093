import { useRef, useCallback } from 'react';
import useWindowEvent from './useWindowEvent';
const useClickAway = (callback) => {
    const ref = useRef(null);
    const handleClick = useCallback((event) => {
        const el = ref?.current;
        const portalContainer = document.querySelector('.MuiModal-root');
        if (!el ||
            el.contains(event.target) ||
            portalContainer?.contains(event.target)) {
            return;
        }
        callback(event);
    }, [callback]);
    useWindowEvent('mousedown', handleClick);
    return ref;
};
export default useClickAway;
