import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, IconButton } from '@mui/material';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useCameraStatus } from '../lib/hooks/useCameraStatus';
import { HeaderMutedVideoIcon, HeaderVideoIcon } from '@/shared/assets/icons';
import { userMediaStore } from '@/shared/model/user-media';
export const CameraControl = observer(() => {
    const { userVideoActive } = userMediaStore;
    const { isCameraButtonDisabled, handleCameraToggle } = useCameraStatus();
    const title = useMemo(() => {
        if (isCameraButtonDisabled) {
            return 'Processing...';
        }
        return userVideoActive ? 'Disable camera' : 'Enable camera';
    }, [isCameraButtonDisabled, userVideoActive]);
    return (_jsx(Tooltip, { title: title, children: _jsx(IconButton, { onClick: !isCameraButtonDisabled ? handleCameraToggle : undefined, size: 'small', color: 'white', children: userVideoActive ? _jsx(HeaderVideoIcon, {}) : _jsx(HeaderMutedVideoIcon, { color: 'error' }) }) }));
});
