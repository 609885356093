import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo, useCallback } from 'react';
import { ModalContent, ContentDescription, ContentActions, ContentHeading } from './styled';
import { modalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
import ButtonCustom from '@/shared/ui/ButtonCustom';
const handleClose = () => {
    modalStore.close();
};
const ChangeTable = observer(({ onSubmit, message, optionalMessage, title, confirmLabel, width, buttonsFullWidth, declineLabel = 'Nevermind', }) => {
    const handleSubmit = useCallback(() => {
        onSubmit();
        modalStore.close();
    }, [onSubmit]);
    return (_jsxs(ModalContent, { width: width, children: [_jsx(ContentHeading, { children: _jsx(TextElement, { fontSize: 24, fontWeight: 600, component: 'h6', children: title }) }), _jsxs(ContentDescription, { children: [_jsx(TextElement, { fontSize: 16, children: message }), optionalMessage ? _jsx(TextElement, { fontSize: 16, children: optionalMessage }) : null] }), _jsxs(ContentActions, { children: [_jsx(ButtonCustom, { variant: 'outlined', fullWidth: buttonsFullWidth, width: buttonsFullWidth ? undefined : 162, height: 48, onClick: handleClose, children: declineLabel }), _jsx(ButtonCustom, { variant: 'contained', color: 'primary', height: 48, fullWidth: buttonsFullWidth, width: buttonsFullWidth ? undefined : 162, onClick: handleSubmit, children: confirmLabel })] })] }));
});
export default memo(ChangeTable);
