import { ListItemButton, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';
import ResponsiveContainer from '@/entities/containers/ResponsiveContainer';
import RouteLink from '@/shared/ui/RouteLink';
export const AppBar = styled.header `
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css `
    color: ${theme.palette.black.main};
    background: ${theme.palette.backgroundPrimary.main};
    z-index: ${theme.zIndex.appBar};
    height: ${theme.values.header.main.height};

    ${theme.breakpoints.down('md')} {
      height: 64px;
    }
  `}
`;
export const Toolbar = styled(ResponsiveContainer) `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css `
    height: ${theme.values.header.main.height};
    min-height: ${theme.values.header.main.height};

    ${theme.breakpoints.down('md')} {
      min-height: 64px;
      height: 64px;
    }
  `}
`;
export const DesktopLink = styled(RouteLink) `
  ${({ theme }) => css `
    &.active {
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: ${theme.palette.primary.main};
      }
    }
  `}
`;
export const MobileLink = styled(RouteLink) `
  ${({ theme }) => css `
    &.active {
      &::after {
        content: '';
        width: 9px;
        height: 9px;
        margin-left: 8px;
        border-radius: 50%;
        background-color: ${theme.palette.white.main};
      }
    }
  `}
`;
export const ListItemDesktop = styled.li `
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(18)};
    color: ${theme.palette.black.main};
  `}
`;
export const ListItemMobile = styled.li `
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(18)};
    color: ${theme.palette.white.main};
  `}
`;
export const SocialsWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
`;
export const SocialsItem = styled.li ``;
export const SocialsButton = styled(IconButton) `
  ${({ theme }) => css `
    color: ${theme.palette.white.main};

    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: ${theme.palette.white.main};
    }
  `}
`;
export const LogoLink = styled(RouteLink) `
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;

  ${({ theme }) => css `
    ${theme.breakpoints.down('md')} {
      position: relative;
      margin: 0;
    }
  `}
`;
export const DesktopNavbar = styled.nav `
  display: flex;
  margin-left: -14px;

  ${({ theme }) => css `
    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `}
`;
export const NavbarMobile = styled.nav `
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;
  gap: 16px;

  will-change: transform;
  transform: translateX(105%);
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: translateX(0);
  }

  ${({ theme }) => css `
    background: ${theme.palette.primary.main};

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `}
`;
export const BurgerMenu = styled(IconButton) `
  display: none;

  ${({ theme }) => css `
    ${theme.breakpoints.down('md')} {
      display: flex;

      & .MuiSvgIcon-root {
        width: 32px;
        height: 32px;
      }
    }

    ${theme.breakpoints.down('xs')} {
      & .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
      }
    }
  `}
`;
export const NavbarMobileHeading = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & .logo {
    ${({ theme }) => css `
      ${theme.breakpoints.down('xs')} {
        width: 32px;
        height: 28px;
      }
    `}
  }
`;
export const CloseButton = styled(IconButton) `
  max-width: fit-content;
  max-height: fit-content;
`;
export const SignInButton = styled(ListItemButton).attrs(() => ({
    component: 'button',
})) `
  white-space: nowrap;
  max-width: fit-content;
  border-radius: 50px;
  height: 35px;
  min-width: 82px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css `
    color: ${theme.palette.black.main};
    border: 1px solid ${theme.palette.black.main};
    font-weight: 500;
    font-size: ${theme.util.pxToRem(18)};

    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `}
`;
