import { useEffect } from 'react';
const useResizeObserver = (element, action) => {
    useEffect(() => {
        if (!element) {
            return;
        }
        const observer = new ResizeObserver(action);
        observer.observe(element);
        return () => observer.disconnect();
    }, [action, element]);
};
export const useResizeObserverRef = (elementRef, action) => {
    useEffect(() => {
        if (!elementRef.current) {
            return;
        }
        const observer = new ResizeObserver(action);
        observer.observe(elementRef.current);
        return () => observer.disconnect();
    }, [action, elementRef]);
};
export default useResizeObserver;
