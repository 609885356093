import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { OptionsWrapper } from './styled';
import AddFriendForm from '../../model/AddFriendForm';
import { HeadingButton } from '@/entities/containers/AsideLayout';
import { AddCircledIcon } from '@/shared/assets/icons';
import { modalStore } from '@/shared/model/modal';
import CustomTooltip from '@/shared/ui/CustomTooltip';
const handleOpenAddFriend = () => {
    modalStore.open({
        content: _jsx(AddFriendForm, {}),
    });
};
const Options = () => (_jsx(OptionsWrapper, { children: _jsx(CustomTooltip, { title: 'Add friend', placement: 'bottom', children: _jsx(HeadingButton, { onClick: handleOpenAddFriend, children: _jsx(AddCircledIcon, { color: 'primary', height: 24, width: 24 }) }) }) }));
export default memo(Options);
