import { Backdrop, Modal } from '@mui/material';
import styled, { css } from 'styled-components';
export const ModalContainer = styled(Modal) `
  ${({ theme }) => css `
    max-height: calc(100dvh - ${theme.values.header.room.height});
    margin-top: ${theme.values.header.room.height};
  `}
`;
export const BackdropStyled = styled(Backdrop) `
  background: none;
  margin-right: 200px;

  ${({ theme }) => css `
    max-height: calc(100dvh - ${theme.values.header.room.height});
  `}
`;
