import { jsx as _jsx } from "react/jsx-runtime";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled, { css } from 'styled-components';
const CustomTooltip = styled(({ className, disableInteractive = true, ...props }) => (_jsx(Tooltip, { ...props, disableInteractive: disableInteractive, classes: { popper: className } }))) `
  ${({ theme, disabled }) => css `
    & .${tooltipClasses.arrow} {
      color: ${theme.palette.white.main};
    }

    & .${tooltipClasses.tooltip} {
      background-color: ${theme.palette.white.main};
      color: ${disabled ? '#ABA7AF' : theme.palette.black.main};
      box-shadow: 0px 0px 2px 2px rgba(55, 69, 87, 0.1);
      font-size: 14px;
      border-radius: 8px;
      padding: 8px 12px;
    }
  `}
`;
export default CustomTooltip;
