import { friendStore } from '@/shared/model/friends';
import DateService from '@/shared/lib/utils/date';
const LAST_MESSAGE_HOURS = 12;
const useSearchFriends = (searchValue) => {
    const { friends } = friendStore;
    const filteredFriends = friends.length
        ? friends.filter((friend) => friend.lastChatMessage)
        : undefined;
    const getSearchedFriends = () => {
        if (!filteredFriends)
            return [];
        if (searchValue)
            return filteredFriends.filter(({ name }) => name.toLocaleLowerCase().startsWith(searchValue.toLocaleLowerCase()));
        return filteredFriends;
    };
    const searchedFriends = getSearchedFriends();
    const sortedSearchedFriends = searchedFriends
        .filter((f) => {
        if (!f.lastChatMessage?.sentTime) {
            return false;
        }
        const currentDate = new Date();
        const lastMessageDate = new Date(f.lastChatMessage.sentTime);
        const diff = DateService.hourDiff(currentDate, lastMessageDate);
        return diff <= LAST_MESSAGE_HOURS;
    })
        .sort((a, b) => {
        if (!a.lastChatMessage || !b.lastChatMessage)
            return 1;
        // Sorting by unread status
        if (a.lastChatMessage.unread && !b.lastChatMessage.unread)
            return -1;
        if (!a.lastChatMessage.unread && b.lastChatMessage.unread)
            return 1;
        // Parsing date strings into Date objects
        const dateA = new Date(a.lastChatMessage.sentTime);
        const dateB = new Date(b.lastChatMessage.sentTime);
        // Sorting by sentTime if both messages are either unread or read
        if (dateA > dateB)
            return -1;
        if (dateA < dateB)
            return 1;
        // Sorting deleted accounts to the end
        if (a.deleted && !b.deleted)
            return 1;
        if (!a.deleted && b.deleted)
            return -1;
        // If all criteria are equal, maintain the current order
        return 0;
    });
    return [searchedFriends, sortedSearchedFriends];
};
export default useSearchFriends;
