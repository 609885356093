import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, IconButton } from '@mui/material';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useMicrophoneStatus } from '../lib/hooks/useMicrophoneStatus';
import { userMediaStore } from '@/shared/model/user-media';
import { HeaderAudioIcon, HeaderMutedAudioIcon } from '@/shared/assets/icons';
export const MicrophoneControl = observer(() => {
    const { userAudioActive } = userMediaStore;
    const { isMicrophoneButtonDisabled, handleMicrophoneToggle } = useMicrophoneStatus();
    const title = useMemo(() => {
        if (isMicrophoneButtonDisabled) {
            return 'Processing...';
        }
        return userAudioActive ? 'Mute mic' : 'Unmute mic';
    }, [isMicrophoneButtonDisabled, userAudioActive]);
    return (_jsx(Tooltip, { title: title, children: _jsx(IconButton, { size: 'small', color: 'white', onClick: !isMicrophoneButtonDisabled ? handleMicrophoneToggle : undefined, children: userAudioActive ? _jsx(HeaderAudioIcon, {}) : _jsx(HeaderMutedAudioIcon, { color: 'error' }) }) }));
});
