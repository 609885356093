import { memo } from 'react';
import styled, { css } from 'styled-components';
import ButtonCustom from '../ButtonCustom';
const SubmitButtonStyled = styled(ButtonCustom) `
  border-radius: 6px;
  align-self: center;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;

  &:hover {
    opacity: 0.85;
  }

  ${({ $uppercase }) => css `
    ${$uppercase
    ? css `
          text-transform: uppercase;
          letter-spacing: 0.83px;
        `
    : null}
  `}
`;
export default memo(SubmitButtonStyled);
