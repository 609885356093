import { makeAutoObservable } from 'mobx';
import { twinkleSparkleSound } from '@/shared/assets/sounds';
class RoomNotificationsStore {
    audio = new Audio(twinkleSparkleSound);
    _interactions = [];
    _notes = [];
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get interactions() {
        return this._interactions;
    }
    addInteraction = (roomNotification) => {
        this.audio.play();
        return this._interactions.push(roomNotification);
    };
    addNote = (roomNotification) => {
        this.audio.play();
        return this._notes.push(roomNotification);
    };
    clear = () => {
        this._interactions.length = 0;
        this._notes.length = 0;
    };
}
export const roomNotificationsStore = new RoomNotificationsStore();
