import ServiceBuilder from '@/shared/lib/utils/service';
import axiosInstance from '@/shared/api/http';
import { API } from '@/shared/constants/api';
import { userStore } from '@/shared/model/user';
import { roomStore } from '@/shared/model/room';
const saveReportLog = async () => ServiceBuilder.serviceRequest(async () => {
    const { user } = userStore;
    const { id } = user;
    const { tables, room } = roomStore;
    const response = await axiosInstance.post(API.saveReportLog, {
        userId: id,
        roomId: room.id,
        tables: JSON.stringify(tables),
    });
    return response;
});
const saveLogMessage = async (message) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.saveLogMessage, {
        message,
    });
    return response;
});
const DebugService = {
    saveReportLog,
    saveLogMessage,
};
export default DebugService;
