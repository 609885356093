import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { memo } from 'react';
import { ModalContent, CloseButtonWrapper, NotificationContent } from './styled';
import { modalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
import { CloseCircledFilledIcon, NotificationAlertIcon } from '@/shared/assets/icons';
const handleClose = () => {
    modalStore.close();
};
const AlertNotification = ({ message }) => (_jsxs(ModalContent, { children: [_jsx(CloseButtonWrapper, { children: _jsx(IconButton, { size: 'small', color: 'purple', "aria-label": 'Close notification', onClick: handleClose, children: _jsx(CloseCircledFilledIcon, {}) }) }), _jsxs(NotificationContent, { children: [_jsx(NotificationAlertIcon, { color: 'purple' }), typeof message === 'string' ? (_jsx(TextElement, { fontWeight: 600, fontSize: 18, textAlign: 'center', children: message })) : (message)] })] }));
export default memo(AlertNotification);
