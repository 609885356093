import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Column, FooterContainer, FooterContent, SignInButton, ListItemStyled, RightsReserved, } from './styled';
import { events, legals, pages } from './constants';
import SocialsGridItem from './components/SocialsGridItem';
import SignInForm from '@/features/SignInForm';
import { LogoIcon } from '@/shared/assets/icons';
import { BRAND_NAME } from '@/shared/constants/common';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore } from '@/shared/model/modal';
import ListEnhanced from '@/shared/ui/ListEnhanced';
import RouteLink from '@/shared/ui/RouteLink';
import TextElement from '@/shared/ui/TextElement';
import { ROLES } from '@/shared/constants/roles';
const helperText = {
    to: ROUTES.becomeHost.path,
    label: 'Not a host yet?',
    linkLabel: 'Become a host',
};
const handleOpenSignIn = () => {
    modalStore.open({
        content: (_jsx(SignInForm, { title: 'Hosts Sign in', to: ROUTES.speakerDashboard.path, type: ROLES.performer, helperText: helperText })),
    });
};
const Footer = () => {
    const { pathname } = useLocation();
    const isMobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const notDisplay = pathname.startsWith(ROUTES.rooms.path) || pathname.startsWith(ROUTES.speakerDashboard.path);
    const currentYear = new Date().getFullYear();
    return notDisplay ? null : (_jsx(FooterContainer, { children: _jsx(FooterContent, { children: _jsxs(Grid, { container: true, columnGap: '10px', children: [isMobileSize ? (_jsx(SocialsGridItem, {})) : (_jsxs(Grid, { item: true, sm: 2, md: 2, lg: 3, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', children: [_jsx(LogoIcon, { color: 'primary' }), _jsxs(RightsReserved, { children: [_jsx(TextElement, { children: `© ${currentYear} ${BRAND_NAME}.` }), _jsx(TextElement, { children: "All Rights Reserved." })] })] })), _jsx(Grid, { item: true, xs: 12, sm: 2, children: _jsxs(Column, { children: [_jsx(TextElement, { fontWeight: 600, children: "Learn More" }), _jsx(ListEnhanced, { gap: '3px', children: pages.map(({ label, path }) => (_jsx(ListItemStyled, { children: _jsx(RouteLink, { to: path, underline: 'hover', children: label }) }, label))) })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 2.7, md: 2, children: _jsxs(Column, { children: [_jsx(TextElement, { fontWeight: 600, children: "Events" }), _jsxs(ListEnhanced, { gap: '3px', children: [events.map(({ label, path }) => (_jsx(ListItemStyled, { children: _jsx(RouteLink, { to: path, underline: 'hover', children: label }) }, label))), _jsx(ListItemStyled, { children: _jsx(SignInButton, { onClick: handleOpenSignIn, children: "Hosts Sign in" }) })] })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 2.3, md: 2, children: _jsxs(Column, { children: [_jsx(TextElement, { fontWeight: 600, children: "Legal" }), _jsx(ListEnhanced, { gap: '3px', children: legals.map(({ label, path }) => (_jsx(ListItemStyled, { children: _jsx(RouteLink, { to: path, underline: 'hover', children: label }) }, label))) })] }) }), isMobileSize ? (_jsx(Grid, { item: true, xs: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(TextElement, { color: 'helper', fontSize: 14, padding: '16px 0', children: `© ${currentYear} ${BRAND_NAME}. All Rights Reserved.` }) })) : (_jsx(SocialsGridItem, {}))] }) }) }));
};
export default memo(Footer);
