import { FacebookIcon, InstagramOutlinedIcon, LinkedInIcon } from '@/shared/assets/icons';
import { SOCIAL_MEDIA } from '@/shared/constants/common';
import { ROUTES } from '@/shared/constants/routes';
export const pages = [
    {
        label: 'Press',
        path: '/',
    },
    {
        label: 'FAQ',
        path: ROUTES.faq.path,
    },
    {
        label: 'Contact us',
        path: ROUTES.contactUs.path,
    },
];
export const events = [
    {
        label: 'Upcoming events',
        path: ROUTES.events.path,
    },
    {
        label: 'Become a host',
        path: ROUTES.becomeHost.path,
    },
];
export const legals = [
    {
        label: 'Privacy Policy',
        path: ROUTES.privacy.path,
    },
    {
        label: 'Terms',
        path: ROUTES.terms.path,
    },
];
export const socials = [
    {
        href: SOCIAL_MEDIA.facebook,
        Icon: FacebookIcon,
    },
    {
        href: SOCIAL_MEDIA.instagram,
        Icon: InstagramOutlinedIcon,
    },
    {
        href: SOCIAL_MEDIA.linkedin,
        Icon: LinkedInIcon,
    },
];
