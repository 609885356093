import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FriendInvitesWrapper } from './styled';
import FriendInvite from '../../components/FriendInvite';
import { HUB_METHODS } from '@/shared/constants/hub';
import { connectionStore } from '@/shared/model/connection';
import { friendStore } from '@/shared/model/friends';
import NotificationHub from '@/shared/api/hubs/NotificationHub';
const FriendInvites = observer(() => {
    const { invites } = friendStore;
    const { notificationHubConnection } = connectionStore;
    const handleDecline = useCallback((inviteId) => async () => {
        const [, ...rest] = invites;
        friendStore.invites = rest;
        await NotificationHub.sendMessage(HUB_METHODS.notification.send.denyFriendInvite, {
            inviteId,
        });
    }, [invites]);
    const handleInviteAccept = useCallback(() => {
        if (notificationHubConnection) {
            const [invite] = invites;
            const { id: inviteId, roomId: roomJoinId, inviterId } = invite;
            notificationHubConnection.send(HUB_METHODS.notification.send.checkInviterStatus, {
                inviteId,
                inviterId,
                roomId: roomJoinId,
            });
        }
    }, [invites, notificationHubConnection]);
    return (_jsx(FriendInvitesWrapper, { length: invites.length, children: invites.slice(0, 3).map(({ id, inviterId, inviterName, ...invite }, index) => (_jsx(FriendInvite, { ...invite, onAccept: handleInviteAccept, onDecline: handleDecline(id), inviterId: inviterId, inviterName: inviterName, zIndex: 3 - index }, id))) }));
});
export default memo(FriendInvites);
