import { TextField } from '@mui/material';
import styled, { css } from 'styled-components';
export const DefaultTextField = styled(TextField) `
  & .MuiInputBase-root {
    & .MuiInputBase-input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
      }
    }

    ${({ $disableFieldset }) => $disableFieldset
    ? css `
            & fieldset {
              padding: 0;
              border: none;
              outline: none;

              &:hover,
              &:focus {
                border: none;
                outline: none;
              }
            }
          `
    : null}
  }

  ${({ theme, $backgroundColor }) => $backgroundColor
    ? css `
          background-color: ${theme.palette[$backgroundColor].main};
        `
    : null}
`;
