import { useEffect, useState } from 'react';
const useMobileDetection = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const mobilePattern = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/;
        const tabletPattern = /iPad|Android (?!.*Mobile)|Tablet|Silk/;
        const { userAgent } = navigator;
        const isMobileDevice = mobilePattern.test(userAgent);
        const isTabletDevice = tabletPattern.test(userAgent);
        setIsMobile(isMobileDevice && !isTabletDevice);
    }, []);
    return isMobile;
};
export default useMobileDetection;
