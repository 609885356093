import { Container } from '@mui/material';
import { memo } from 'react';
import styled, { css } from 'styled-components';
const ResponsiveContainer = styled(Container) `
  padding: 0;
  min-height: fit-content;

  ${({ theme, $maxWidth }) => css `
    max-width: ${$maxWidth ?? '1250px'};

    @media screen and (max-width: 1350px) {
      padding: 0 32px;
    }

    ${theme.breakpoints.down('md')} {
      padding: 0 16px;
    }
  `}
`;
export default memo(ResponsiveContainer);
