import { useCallback, useState } from 'react';
import { userMediaStore } from '@/shared/model/user-media';
const CAMERA_TIMEOUT_MS = 2 * 1000;
export const useCameraStatus = () => {
    const { toggleVideoMuted } = userMediaStore;
    const [isCameraButtonDisabled, setIsCameraButtonDisabled] = useState(false);
    const handleCameraToggle = useCallback(() => {
        setIsCameraButtonDisabled(true);
        toggleVideoMuted();
        setTimeout(() => {
            setIsCameraButtonDisabled(false);
        }, CAMERA_TIMEOUT_MS);
    }, [toggleVideoMuted]);
    return {
        isCameraButtonDisabled,
        handleCameraToggle,
    };
};
