import { useEffect } from 'react';
import useEvent from './useEvent';
const useInterval = (callback, delay) => {
    const savedCallback = useEvent(callback);
    useEffect(() => {
        const id = setInterval(() => savedCallback(), delay);
        return () => clearInterval(id);
    }, [delay, savedCallback]);
};
export default useInterval;
