import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CircularProgress } from '@mui/material';
import { Suspense, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Main, Wrapper } from './styled';
import DynamicHeader from './components/DynamicHeader';
import Modals from './components/Modals';
import RoomTheme from './components/RoomTheme';
import AsideMenu from '@/widgets/AsideMenu';
import Footer from '@/widgets/Footer';
const Layout = () => (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsx(DynamicHeader, {}), _jsxs(Main, { children: [_jsx(Suspense, { fallback: _jsx(CircularProgress, { color: 'primary' }), children: _jsx(Outlet, {}) }), _jsx(AsideMenu, {})] }), _jsx(Footer, {}), _jsx(RoomTheme, {})] }), _jsx(Modals, {})] }));
export default memo(Layout);
