import axiosInstance from '@/shared/api/http';
import { API } from '@/shared/constants/api';
import ServiceBuilder from '@/shared/lib/utils/service';
const loadFriends = async (userId) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(API.friends, {
        params: { userId },
    });
    return response;
});
export default loadFriends;
