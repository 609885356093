import { useState, useCallback, useEffect } from 'react';
import { connectionStore } from '@/shared/model/connection';
import { userStore } from '@/shared/model/user';
const useConnectToNotifications = (verified) => {
    const { user } = userStore;
    const { id: userId } = user;
    const [isConnected, setIsConnected] = useState(false);
    const connectToNotifications = useCallback(async () => {
        if (!userId)
            return;
        await connectionStore.connectUserToGlobalNotifications(userId);
        setIsConnected(true);
    }, [userId]);
    useEffect(() => {
        if (!isConnected && verified) {
            connectToNotifications();
        }
    }, [connectToNotifications, isConnected, verified]);
    return isConnected;
};
export default useConnectToNotifications;
