import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CenterContainer, NoMaxWidthTooltip, OneTableView } from './styled';
import { AppIcon } from '@/shared/assets/icons';
import { headerStore } from '@/shared/model/header';
import { userStore } from '@/shared/model/user';
import { ViewMode } from '@/shared/constants/viewMode';
import { roomStore } from '@/shared/model/room';
export const ViewModeControl = observer(() => {
    const { viewMode, showControls } = headerStore;
    const { userTable } = userStore;
    const { isRoomWithControls } = roomStore;
    const color = viewMode === ViewMode.DEFAULT ? 'white' : 'secondary';
    const subColor = viewMode === ViewMode.DEFAULT ? 'secondary' : 'white';
    const handleDefaultTableView = () => {
        headerStore.viewMode = ViewMode.DEFAULT;
    };
    const handleMyTableView = () => {
        headerStore.viewMode = ViewMode.MY_TABLE;
    };
    const isMyTableViewEnabled = userTable?.tableName === 'Table';
    return isRoomWithControls && showControls ? (_jsxs(CenterContainer, { children: [_jsx(Tooltip, { disableInteractive: true, title: 'All tables view', children: _jsx(IconButton, { color: color, size: 'small', onClick: handleDefaultTableView, children: _jsx(AppIcon, { width: 28, height: 28 }) }) }), _jsx(NoMaxWidthTooltip, { disableInteractive: true, title: isMyTableViewEnabled
                    ? 'My table view'
                    : 'My table view is only available when sitting in a table', children: _jsx("span", { children: _jsx(IconButton, { disabled: !isMyTableViewEnabled, color: subColor, size: 'small', onClick: handleMyTableView, children: _jsx(OneTableView, {}) }) }) })] })) : null;
});
