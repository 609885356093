import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { InView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz';
import { isAxiosError } from 'axios';
import Chat from '../../entities/Chat';
import { ROUTES } from '@/shared/constants/routes';
import { userStore } from '@/shared/model/user';
import { ChatContentPaper, ChatContent, MessageText, Message, LoaderWrapper, AnchorWrapper, InViewAnchor, } from '@/entities/Chat/styled';
import useFetchChat from '@/widgets/ChatAside/lib/hooks/useFetchChat';
import FriendService from '@/shared/api/services/FriendService';
import { friendStore } from '@/shared/model/friends';
const FriendsChat = observer(({ onClose }) => {
    const { user } = userStore;
    const { friends, activeChatHistory, activeFriendChatId } = friendStore;
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const [pageNumber, setPageNumber] = useState(1);
    const [wasLoading, setWasLoading] = useState(false);
    const thisFriend = useMemo(() => friends.find((friend) => friend.id === activeFriendChatId), [friends, activeFriendChatId]);
    const filteredFriends = useMemo(() => friends.filter((friend) => friend.id !== activeFriendChatId), [friends, activeFriendChatId]);
    const chatContentRef = useRef(null);
    const [loading, error, messages, totalPages, loadingCount] = useFetchChat({
        senderId: user.id,
        receiverId: activeFriendChatId,
        pageNumber,
    });
    useEffect(() => {
        if (loadingCount > 1 && !wasLoading) {
            setWasLoading(true);
        }
    }, [loadingCount, wasLoading]);
    useLayoutEffect(() => {
        friendStore.activeChatHistory = messages;
    }, [messages]);
    const handleSend = useCallback(async (message) => {
        const response = await FriendService.sendChatMessage(user.id, activeFriendChatId, message);
        if (!response || isAxiosError(response)) {
            return;
        }
        const { data } = response;
        if (!data || !thisFriend)
            return;
        friendStore.activeChatHistory = [data, ...activeChatHistory];
        friendStore.friends = [
            {
                ...thisFriend,
                lastChatMessage: {
                    senderId: user.id,
                    data: message,
                    receiverId: activeFriendChatId,
                    sentTime: utcToZonedTime(new Date(), 'Asia/Jerusalem').toISOString(),
                    unread: false,
                },
            },
            ...filteredFriends,
        ];
    }, [activeChatHistory, filteredFriends, user.id, activeFriendChatId, thisFriend]);
    useLayoutEffect(() => {
        if (!chatContentRef.current)
            return;
        if (activeChatHistory.length > 20) {
            chatContentRef.current.scrollTo(0, chatContentRef.current.scrollHeight / loadingCount);
            return;
        }
        chatContentRef.current.scrollTo(0, chatContentRef.current.scrollHeight);
    }, [activeChatHistory.length, loadingCount]);
    const handleInView = useCallback((inView) => {
        if (inView) {
            setPageNumber((prev) => prev + 1);
        }
    }, []);
    const reversedChatHistory = useMemo(() => [...activeChatHistory].reverse(), [activeChatHistory]);
    if (!thisFriend)
        return null;
    return (_jsx(Chat, { connected: true, friendId: thisFriend.id, picture: thisFriend.profilePicture ?? '', title: thisFriend.name, onSend: handleSend, onClose: onClose, isUserDeleted: thisFriend.deleted, children: _jsx(ChatContentPaper, { ref: chatContentRef, elevation: 0, "$isLobby": isLobby, children: _jsx(ChatContent, { children: !wasLoading && loading ? (_jsx(LoaderWrapper, { children: _jsx(CircularProgress, { color: 'primary' }) })) : (_jsx(_Fragment, { children: error ? (_jsx(Typography, { textAlign: 'center', children: `Error: ${error.message}` })) : (reversedChatHistory.map(({ senderId, message, id }, index) => {
                        const mine = senderId === user.id;
                        const isLastMessage = index === 0;
                        return (_jsxs(_Fragment, { children: [reversedChatHistory.length && isLastMessage && pageNumber < totalPages ? (_jsx(AnchorWrapper, { children: loading ? (_jsx(CircularProgress, { color: 'primary', size: 30 })) : (_jsx(_Fragment, { children: totalPages > pageNumber ? (_jsx(InView, { onChange: handleInView, children: ({ ref }) => _jsx(InViewAnchor, { ref: ref }) })) : null })) })) : null, _jsx(Message, { mine: mine, children: _jsx(MessageText, { children: message }, id) }, id)] }));
                    })) })) }) }) }));
});
export default memo(FriendsChat);
