import axiosInstance from '../http';
import { API } from '@/shared/constants/api';
import ServiceBuilder from '@/shared/lib/utils/service';
const approveFriendRequest = async (requesterId, id) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.approveFriendRequest, {
        acceptorId: id,
        friendRequesterId: requesterId,
    });
    return response;
});
const declineFriendRequest = async (requesterId, id) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.declineFriendRequest, {
        declinerId: id,
        friendRequesterId: requesterId,
    });
    return response;
});
const requestFriend = async (id, friendEmail) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.sendFriendRequest, {
        userId: id,
        friendEmail,
    });
    return response;
});
const requestAddFriendFromRoom = async (id, friendId) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.sendAddFromRoomFriendRequest, {
        userId: id,
        friendId,
    });
    return response;
});
const unfriend = async (friendId, userId) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.delete(API.unfriend, {
        params: {
            userId,
            friendId,
        },
    });
    return response;
});
const loadChatHistory = async (senderId, receiverId, pageNumber, pageSize = 20) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(API.friendsChatHistory, {
        params: {
            senderId,
            receiverId,
            pageNumber,
            pageSize,
        },
    });
    return response;
});
const sendChatMessage = async (senderId, receiverId, message) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.friendSendChat, {
        senderId,
        receiverId,
        message,
    });
    return response;
});
const FriendService = {
    approveFriendRequest,
    declineFriendRequest,
    requestFriend,
    unfriend,
    loadChatHistory,
    sendChatMessage,
    requestAddFriendFromRoom,
};
export default FriendService;
