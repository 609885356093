import { Container } from '@mui/material';
import { memo } from 'react';
import styled, { css } from 'styled-components';
export const AppBar = memo(styled.header `
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  ${({ theme, bordered }) => css `
    color: ${theme.palette.white.main};
    z-index: ${theme.zIndex.appBar};
    min-height: ${theme.values.header.room.height};
    max-height: ${theme.values.header.room.height};

    ${bordered
    ? css `
          border: 1px solid #e0e0e0;
          background: ${theme.palette.white.main};
        `
    : css `
          background: transparent;
        `}
  `}
`);
export const Toolbar = styled(Container) `
  max-width: 1920px;
  ${({ theme }) => css `
    min-height: ${theme.values.header.room.height};
    max-height: ${theme.values.header.room.height};
  `}
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
export const LeftContainer = styled.div `
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${({ theme }) => css `
    gap: ${theme.spacing(12.5)};
  `}
`;
export const LogoButton = styled.button `
  outline: none;
  border: none;
  background: none;
  color: inherit;

  pointer-events: none;

  &:hover {
    outline: none;
    border: none;
  }
`;
