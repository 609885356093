export const ROOM_THEMES = {
    default: 'default',
    cinema: 'cinema',
    library: 'library',
    northernLights: 'northernLights',
    hauntedMansion: 'hauntedMansion',
    beachParty: 'beachParty',
    fantasyForest: 'fantasyForest',
    speedDating: 'speedDating',
    speedDatingMatch: 'speedDatingMatch',
};
