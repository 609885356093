import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
const LinkStyled = styled(NavLink) `
  ${({ theme, $color = 'inherit', $fontWeight = 'inherit', $noRem, $fontSize = 'inherit', $underline = 'none', $inline, }) => css `
    ${$inline
    ? css `
          display: inline;
        `
    : css `
          display: flex;
          align-items: center;
          gap: 3px;
        `}
    font-size: ${theme.util.pxToRem(16)};
    font-weight: ${$fontWeight};
    color: ${$color === 'inherit' ? $color : theme.palette[$color].main};
    font-size: ${!$noRem && typeof $fontSize === 'number'
    ? theme.util.pxToRem($fontSize)
    : $fontSize};

    ${$underline === 'none'
    ? css `
          text-decoration: none;
        `
    : null}

    ${$underline === 'hover'
    ? css `
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        `
    : null}

    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: ${$color === 'inherit' ? $color : theme.palette[$color].main};
    }
  `}
`;
export default memo(LinkStyled);
