import { useEffect } from 'react';
import useEvent from './useEvent';
import { connectionStore } from '@/shared/model/connection';
const useTableChatHubReceive = (hubMethod, callback) => {
    const savedCallback = useEvent(callback);
    const { tableChatHubConnection } = connectionStore;
    useEffect(() => {
        tableChatHubConnection?.on(hubMethod, (hubMessage) => {
            savedCallback(hubMessage);
        });
        return () => {
            tableChatHubConnection?.off(hubMethod);
        };
    }, [hubMethod, savedCallback, tableChatHubConnection]);
};
export default useTableChatHubReceive;
