import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { memo } from 'react';
const TextElementStyled = styled(Typography) `
  ${({ theme, fontSize = 'inherit', color = 'inherit', $noRem, $textGradient, $gradientBackground = 'linear-gradient(279.88deg, #e54777 0%, #8341ff 100%)' }) => css `
    color: ${color === 'inherit' ? color : theme.palette[color].main};
    font-size: ${!$noRem && typeof fontSize === 'number' ? theme.util.pxToRem(fontSize) : fontSize};
    ${$textGradient
    ? css `
          background: ${$gradientBackground};
          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
        `
    : null}
  `}
`;
export default memo(TextElementStyled);
