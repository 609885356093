import ServiceBuilder from '@/shared/lib/utils/service';
import { connectionStore } from '@/shared/model/connection';
const sendMessage = async (method, message) => ServiceBuilder.socketRequest(async () => {
    const { notificationHubConnection } = connectionStore;
    if (!notificationHubConnection)
        return false;
    await notificationHubConnection.send(method, message);
    return true;
});
const NotificationHub = {
    sendMessage,
};
export default NotificationHub;
