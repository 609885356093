import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';
export const Content = styled.section `
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 35px;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;
export const ContentHeader = styled.header `
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;
export const CloseButton = styled(IconButton) `
  position: absolute;
  top: 0;
  right: 0;

  & .MuiSvgIcon-root {
    height: 19px;
    width: 19px;
  }
`;
export const ErrorMessage = styled.p `
  position: absolute;
  width: 100%;
  bottom: 95px;
  text-align: center;
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(14)};
    color: ${theme.palette.error.main};
  `}
`;
export const Title = styled.h4 `
  text-align: center;
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(24)};
    color: ${theme.palette.black.main};
  `}
`;
export const SignInFormWrapper = styled.form `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 365px;
  gap: 25px;
  position: relative;
`;
export const SignUpHelperText = styled.p `
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(14)};
    color: ${theme.palette.black.main};
    display: flex;
    gap: 3px;
  `}
`;
