import { isAxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { getDetailsByRole, verifyAccessToken } from '../../api';
import { ROLES } from '@/shared/constants/roles';
import { userStore } from '@/shared/model/user';
const useVerifyUser = () => {
    const [verifying, setVerifying] = useState(true);
    const [verified, setVerified] = useState(false);
    useEffect(() => {
        const verify = async () => {
            setVerifying(true);
            const verifyDetails = await verifyAccessToken();
            if (!verifyDetails || isAxiosError(verifyDetails)) {
                setVerifying(false);
                setVerified(false);
                return;
            }
            const { id, role, isAdmin } = verifyDetails.data;
            const response = await getDetailsByRole(id, role);
            if (!response || isAxiosError(response)) {
                setVerifying(false);
                setVerified(false);
                return;
            }
            userStore.user = {
                ...response.data,
                dtBirthDate: new Date(response.data.dtBirthDate.toString().slice(0, 10)),
                role: role ?? ROLES.user,
                isAdmin: isAdmin ?? false,
            };
            setVerifying(false);
            setVerified(true);
        };
        verify();
    }, []);
    return [verifying, verified];
};
export default useVerifyUser;
