import { makeAutoObservable } from 'mobx';
const noop = () => { };
class VideosdkStore {
    _meetingId = null;
    _ready = false;
    _users = {};
    _toggleAudio = noop;
    _unmuteAudio = noop;
    _muteAudio = noop;
    _toggleVideo = noop;
    _leave = noop;
    constructor() {
        makeAutoObservable(this);
    }
    get meetingId() {
        return this._meetingId;
    }
    set meetingId(meetingId) {
        this._meetingId = meetingId;
    }
    get users() {
        return this._users;
    }
    set users(users) {
        this._users = { ...users };
    }
    get ready() {
        return this._ready;
    }
    set ready(ready) {
        this._ready = ready;
    }
    get toggleAudio() {
        return this._toggleAudio;
    }
    set toggleAudio(toggleAudio) {
        this._toggleAudio = toggleAudio;
    }
    get unmuteAudio() {
        return this._unmuteAudio;
    }
    set unmuteAudio(unmuteAudio) {
        this._unmuteAudio = unmuteAudio;
    }
    get muteAudio() {
        return this._muteAudio;
    }
    set muteAudio(muteAudio) {
        this._muteAudio = muteAudio;
    }
    get toggleVideo() {
        return this._toggleVideo;
    }
    set toggleVideo(toggleVideo) {
        this._toggleVideo = toggleVideo;
    }
    get leave() {
        return this._leave;
    }
    set leave(leave) {
        this._leave = leave;
    }
    clear() {
        this._meetingId = null;
        this._ready = false;
        this._users = {};
        this._toggleAudio = noop;
        this._unmuteAudio = noop;
        this._muteAudio = noop;
        this._toggleVideo = noop;
    }
}
export const videosdkStore = new VideosdkStore();
