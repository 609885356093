import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';
import ResponsiveContainer from '@/entities/containers/ResponsiveContainer';
export const FooterContainer = styled.footer `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 64px 0;
  min-height: 275px;
  ${({ theme }) => css `
    background-color: ${theme.palette.backgroundPrimary.main};

    ${theme.breakpoints.down('sm')} {
      padding: 32px 0;
    }
  `}
`;
export const FooterContent = styled(ResponsiveContainer) `
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: fit-content;
`;
export const RightsReserved = styled.div `
  ${({ theme }) => css `
    color: ${theme.palette.helper.main};
    font-size: ${theme.util.pxToRem(14)};

    ${theme.breakpoints.down('lg')} {
      padding: 15px 0;
    }
  `}
`;
export const ListItemStyled = styled.li `
  padding: 3px 0;
  color: #4b3a5a;
`;
export const SocialsItem = styled.li ``;
export const Column = styled.section `
  display: flex;
  flex-direction: column;
  gap: 3px;
  ${({ theme }) => css `
    color: ${theme.palette.lightBlack.main};
    font-size: ${theme.util.pxToRem(16)};

    ${theme.breakpoints.down('sm')} {
      border-bottom: 1px solid ${theme.palette.common.black};
      padding-bottom: 16px;
      padding-top: 16px;
      width: 100%;
    }
  `}
`;
export const SignInButton = styled.button `
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;

  &:hover {
    text-decoration: underline;
    border: none;
    outline: none;
  }
`;
export const SocialsButton = styled(IconButton) `
  ${({ theme }) => css `
    color: ${theme.palette.primary.main};

    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: ${theme.palette.primary.main};
    }

    ${theme.breakpoints.down('lg')} {
      & .MuiSvgIcon-root {
        width: 26px;
        height: 26px;
      }
    }

    ${theme.breakpoints.down('md')} {
      margin-left: -7px;
      & .MuiSvgIcon-root {
        width: 23px;
        height: 23px;
      }
    }
  `}
`;
