import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
export const DeleteFormWrapper = styled.form `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 35px 50px 0 85px;

  gap: 40px;
`;
export const BackButton = styled(Button) `
  width: 65px;
  height: 24px;
  color: #8f8f8f;
`;
export const ResetButton = styled(Button) `
  width: 144px;
  height: 48px;
  border-radius: 4px;
  color: #6945fa;
  border: 1px solid #6945fa;
`;
export const Description = styled(Typography) `
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #39393a;
`;
export const Title = styled(Typography) `
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;

  color: #39393a;
`;
export const DeleteFormHead = styled.div `
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
export const DeleteFormBody = styled.div `
  display: flex;
  flex-direction: column;

  width: 363px;

  gap: 24px;
`;
export const DeleteFormButtons = styled.div `
  display: flex;
  gap: 15px;
`;
export const InputWithTitle = styled.div ``;
