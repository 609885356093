import { List } from '@mui/material';
import { memo } from 'react';
import styled, { css } from 'styled-components';
const ListEnhancedStyled = styled(List).attrs(() => ({
    disablePadding: true,
})) `
  ${({ $row, $gap, $padding, $paddingLeft, $paddingRight, $paddingTop, $paddingBottom, $margin, $marginLeft, $marginRight, $marginTop, $marginBottom, $listStyle, $flexWrap, }) => css `
    display: flex;
    flex-direction: ${$row ? 'row' : 'column'};

    ${$flexWrap
    ? css `
          flex-wrap: ${$flexWrap};
        `
    : null}

    ${$gap
    ? css `
          gap: ${$gap};
        `
    : null}

    ${$listStyle
    ? css `
          & > li {
            list-style: ${$listStyle};
          }
        `
    : null}

    ${$padding
    ? css `
          padding: ${$padding};
        `
    : null}

    ${$paddingLeft
    ? css `
          padding-left: ${$paddingLeft};
        `
    : null}

    ${$paddingRight
    ? css `
          padding-right: ${$paddingRight};
        `
    : null}

    ${$paddingTop
    ? css `
          padding-top: ${$paddingTop};
        `
    : null}

    ${$paddingBottom
    ? css `
          padding-bottom: ${$paddingBottom};
        `
    : null}

      ${$margin
    ? css `
          margin: ${$margin};
        `
    : null}

    ${$marginLeft
    ? css `
          margin-left: ${$marginLeft};
        `
    : null}

    ${$marginRight
    ? css `
          margin-right: ${$marginRight};
        `
    : null}

    ${$marginTop
    ? css `
          margin-top: ${$marginTop};
        `
    : null}

    ${$marginBottom
    ? css `
          margin-bottom: ${$marginBottom};
        `
    : null}
  `}
`;
export default memo(ListEnhancedStyled);
