import { useCallback, useState } from 'react';
import { userMediaStore } from '@/shared/model/user-media';
const MICROPHONE_TIMEOUT_MS = 2 * 1000;
export const useMicrophoneStatus = () => {
    const { toggleAudioMuted } = userMediaStore;
    const [isMicrophoneButtonDisabled, setIsMicrophoneButtonDisabled] = useState(false);
    const handleMicrophoneToggle = useCallback(() => {
        setIsMicrophoneButtonDisabled(true);
        toggleAudioMuted();
        setTimeout(() => {
            setIsMicrophoneButtonDisabled(false);
        }, MICROPHONE_TIMEOUT_MS);
    }, [toggleAudioMuted]);
    return {
        isMicrophoneButtonDisabled,
        handleMicrophoneToggle,
    };
};
