import { Avatar, ListItemButton, Paper, TextField } from '@mui/material';
import styled, { css } from 'styled-components';
export const ChatContainer = styled.section `
  display: flex;
  width: 100%;
  ${({ theme }) => css `
    height: calc(100dvh - ${theme.values.header.room.height});
  `}

  border-radius: 10px 0 0 10px;
  flex-direction: column;

  & .EmojiPickerReact.epr-main {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border: none;
    ${({ theme }) => css `
      font-family: ${theme.font.family.primary};
    `}

    & .epr-search {
      ${({ theme }) => css `
        font-family: ${theme.font.family.primary};
      `}
    }

    & .epr-body {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
export const Heading = styled(Paper) `
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 56px;
  justify-content: space-between;
  border-radius: 10px 0 0 0;
  -webkit-border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  padding: 0 10px;
  z-index: 1;
`;
export const HeadingContentContainer = styled.div `
  display: flex;
  height: 100%;
  align-items: center;

  ${({ gap }) => css `
    gap: ${gap};
  `}
`;
export const ChatName = styled.p `
  ${({ theme }) => css `
    padding: 2px 0;
    color: ${theme.palette.black.main};
    font-size: ${theme.util.pxToRem(16)};
    font-weight: 500;
  `}
`;
export const ProfilePicture = styled(Avatar) `
  height: 36px;
  width: 36px;

  ${({ $withPictureBorder }) => $withPictureBorder
    ? css `
          border: 1px solid black;
        `
    : null}
`;
export const ChatContentContainer = styled.div `
  flex: auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
`;
export const ChatContentPaper = styled(Paper) `
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  position: relative;

  ${({ $isLobby }) => css `
    ${$isLobby
    ? css `
          &::-webkit-scrollbar-thumb {
            ${({ theme }) => css `
              background: ${theme.palette.primary.main};
            `}
          }

          &::-webkit-scrollbar-track {
            background: #e2e2e2;
          }
        `
    : css `
          &::-webkit-scrollbar-thumb {
            background: #a2a2bf;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }
        `}
  `}

  &::-webkit-scrollbar {
    width: 5px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: 0px 20px 70px rgba(55, 69, 87, 0.3);
    border-right: none;
    border-left: none;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;
export const ChatContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 0 10px 0;
`;
export const Time = styled.time `
  display: flex;
  align-self: end;

  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(10)};
  `}
`;
export const Message = styled.article `
  ${({ theme, mine }) => mine
    ? css `
          align-self: flex-end;
          background-color: ${theme.palette.purple.main};
          color: ${theme.palette.white.main};
        `
    : css `
          background-color: #ececf2;
          align-self: flex-start;

          & time {
            color: #8f8f8f;
          }
        `}
  padding: 8px 12px;
  display: flex;
  width: fit-content;
  min-width: 100px;
  max-width: 275px;
  flex-direction: column;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
`;
export const MessageText = styled.p `
  ${({ theme }) => css `
    padding: 2px 0;
    font-size: ${theme.util.pxToRem(15)};
    line-height: 1.46;
    max-width: fit-content;
    white-space: pre-line;
    word-wrap: break-word;
  `}
`;
export const MessageSender = styled.p `
  ${({ theme, color = 'white' }) => css `
    padding: 2px 0;
    margin-bottom: 1px;
    font-size: ${theme.util.pxToRem(15)};
    line-height: 1.46;
    color: ${color};
    max-width: fit-content;
    font-weight: 500;
  `}
`;
export const BottomContainer = styled.div `
  min-height: fit-content;
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 7px 10px 26px 10px;
  align-items: center;
  color: #666668;
`;
export const MessageInput = styled(TextField) `
  background-color: #f0f2f4;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;

  & .MuiInputBase-root {
    padding: 5px;

    ${({ theme }) => css `
      font-size: ${theme.util.pxToRem(15)};
    `}

    & fieldset {
      border: none;
      outline: none;

      &:hover,
      &:focus {
        border: none;
        outline: none;
      }
    }

    &::placeholder {
      color: #666668;
    }
  }

  & ::-webkit-scrollbar {
    display: none;
  }
`;
export const LoaderWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const ActionButton = styled(ListItemButton).attrs(() => ({
    component: 'button',
})) `
  ${({ theme }) => css `
    color: ${theme.palette.error.main};
    gap: ${theme.spacing(4)};
    font-size: ${theme.util.pxToRem(14)};
  `}
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
`;
export const AnchorWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10px;
`;
export const InViewAnchor = styled.span `
  height: 10px;
  width: 10px;
`;
