import styled, { css } from 'styled-components';
import { Button, IconButton } from '@mui/material';
import CountryPicker from '@/shared/ui/CountryPicker';
export const CountryPickerStyled = styled(CountryPicker) `
  & .MuiAutocomplete-inputRoot {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 14px;
    & .MuiAutocomplete-input {
      padding: 15px 15px 15px 8px;
    }
    & .MuiAutocomplete-endAdornment {
      margin-top: 3px;
      right: 13px;
    }
  }
`;
export const SignUpFormWrapper = styled.form `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;
export const Column = styled.div `
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 100%;
`;
export const ProfilePicture = styled.img `
  width: 100%;
  height: 100%;
  min-width: 105px;
  min-height: 105px;
  object-fit: cover;
  border-radius: 8px;
`;
export const UploadButtonWrapper = styled.div `
  display: flex;
  position: relative;
  border-radius: 8px;
  width: 105px;
  height: 105px;

  ${({ theme, error }) => css `
    border: 1px solid ${error ? '#FF4848' : theme.palette.primary.main};
  `}
`;
export const UploadButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(25)};
    color: ${theme.palette.primary.main};
  `}

  padding: 0;
`;
export const ResetPictureButton = styled(IconButton) `
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  right: -5px;
  max-width: 31px;
  max-height: 31px;
  ${({ theme }) => css `
    background-color: ${theme.palette.white.main};

    &:hover {
      background-color: ${theme.palette.white.main};
    }

    color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
  `}
`;
export const FileInputUploader = styled.input `
  display: none;
`;
export const ProfilePictureSection = styled.section `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
export const TitleWrapper = styled.div `
  display: flex;
  gap: 20px;
  width: 100%;
`;
export const ProfilePictureTitle = styled.p `
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(15)};
    color: ${theme.palette.black.main};
  `}
`;
export const PictureHint = styled.p `
  ${({ theme }) => css `
    padding: 2px 0;
    font-size: ${theme.util.pxToRem(14)};
    color: ${theme.palette.inactive.main};
  `}
`;
export const PictureError = styled(PictureHint) `
  position: absolute;
  bottom: 24px;
  color: #ff4848;
`;
export const CheckboxesContainer = styled.div `
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;

  & .MuiFormControlLabel-root {
    & .MuiButtonBase-root {
      padding: 2px;
      margin-right: 10px;
      margin-left: 4px;
    }

    & .MuiTypography-root {
      ${({ theme }) => css `
        font-size: ${theme.util.pxToRem(15)};
      `}
    }
  }
`;
export const Link = styled.a `
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(15)};
    color: ${theme.palette.primary.main};
    text-decoration: none;
  `}

  &:visited,
  &:hover,
  &:active,
  &:focus {
    ${({ theme }) => css `
      color: ${theme.palette.primary.main};
    `}
  }
`;
export const ErrorLabel = styled.span `
  margin-left: 10px;
  ${({ theme }) => css `
    color: ${theme.palette.error.main};
  `}
`;
