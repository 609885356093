import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { memo } from 'react';
import { ModalContent, CloseButtonWrapper, NotificationContent, OkButton, TextWrapper, } from './styled';
import { CloseIcon, WarningIcon } from '@/shared/assets/icons';
import { modalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
const handleClose = () => {
    modalStore.close();
};
const ImageSizeAlert = ({ maxSizeMB = 10, onClose }) => (_jsxs(ModalContent, { children: [_jsx(CloseButtonWrapper, { children: _jsx(IconButton, { size: 'small', "aria-label": 'Close notification', onClick: onClose ?? handleClose, children: _jsx(CloseIcon, { width: 20, height: 20 }) }) }), _jsxs(NotificationContent, { children: [_jsx(WarningIcon, {}), _jsxs(TextWrapper, { children: [_jsx(TextElement, { fontWeight: 600, fontSize: 18, children: "Oops, your picture is too large!" }), _jsx(TextElement, { fontSize: 14, children: `Please upload an image that's under ${maxSizeMB}MB.` })] }), _jsx(OkButton, { onClick: onClose ?? handleClose, children: "Ok" })] })] }));
export default memo(ImageSizeAlert);
