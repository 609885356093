import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '@/shared/constants/routes';
import { ROLES } from '@/shared/constants/roles';
const PromotionalUnsubscribe = lazy(() => import('./PromotionalUnsubscribe'));
const Home = lazy(() => import('./home'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const SignUp = lazy(() => import('./SignUp'));
const SpeakerDashboard = lazy(() => import('./SpeakerDashboard'));
const Terms = lazy(() => import('./Terms'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const Lobby = lazy(() => import('./lobby'));
const Room = lazy(() => import('./Room'));
const BecomeHost = lazy(() => import('./BecomeHost'));
const Events = lazy(() => import('./Events'));
const Event = lazy(() => import('./Event'));
const ContactUs = lazy(() => import('./ContactUs'));
// const OurTeam = lazy(() => import('./OurTeam'));
const FAQ = lazy(() => import('./FAQ'));
export const PAGES = [
    {
        path: ROUTES.home.path,
        component: _jsx(Home, {}),
    },
    {
        path: ROUTES.becomeHost.path,
        component: _jsx(BecomeHost, {}),
    },
    // {
    //   path: ROUTES.ourTeam.path,
    //   component: <OurTeam />,
    // },
    {
        path: ROUTES.faq.path,
        component: _jsx(FAQ, {}),
    },
    {
        path: ROUTES.forgotPassword.path,
        component: _jsx(ForgotPassword, { type: ROLES.user }),
    },
    {
        path: ROUTES.forgotPassword.performer.path,
        component: _jsx(ForgotPassword, { type: ROLES.performer }),
    },
    {
        path: ROUTES.forgotPassword.reset.path,
        component: _jsx(ResetPassword, { type: ROLES.user }),
    },
    {
        path: ROUTES.forgotPassword.performer.reset.path,
        component: _jsx(ResetPassword, { type: ROLES.performer }),
    },
    {
        path: ROUTES.events.path,
        component: _jsx(Events, {}),
    },
    {
        path: ROUTES.events.event.path,
        component: _jsx(Event, {}),
    },
    {
        path: ROUTES.privacy.path,
        component: _jsx(PrivacyPolicy, {}),
    },
    {
        path: ROUTES.signUp.path,
        component: _jsx(SignUp, {}),
    },
    {
        path: ROUTES.terms.path,
        component: _jsx(Terms, {}),
    },
    {
        path: ROUTES.unsubscribe.path,
        component: _jsx(PromotionalUnsubscribe, {}),
    },
    {
        path: ROUTES.contactUs.path,
        component: _jsx(ContactUs, {}),
    },
    {
        path: '*',
        component: _jsx(Navigate, { to: ROUTES.home.path, replace: true }),
    },
];
export const PAGES_PROTECTED = [
    {
        path: ROUTES.rooms.path,
        component: _jsx(Lobby, {}),
    },
    {
        path: ROUTES.rooms.room.path,
        component: _jsx(Room, {}),
    },
    {
        path: ROUTES.speakerDashboard.path,
        component: _jsx(SpeakerDashboard, {}),
    },
];
