import { connectionStore } from '@/shared/model/connection';
import ServiceBuilder from '@/shared/lib/utils/service';
export const sendInteraction = ({ method, message }) => ServiceBuilder.socketRequest(async () => {
    const { interactionHubConnection } = connectionStore;
    if (!interactionHubConnection)
        return false;
    if (message) {
        await interactionHubConnection.send(method, message);
    }
    else {
        await interactionHubConnection.send(method);
    }
    return true;
});
