import { useCallback, useEffect, useState } from 'react';
import roomPopupsStore from '@/shared/lib/store/roomPopups';
import { pendingInteractionsStore } from '@/shared/model/interactions';
import { userStore } from '@/shared/model/user';
export const useLookOnMe = () => {
    const [isLookingOnMe, setIsLookingOnMe] = useState(false);
    const { lookedByUserC } = pendingInteractionsStore;
    const { user } = userStore;
    useEffect(() => {
        if (!lookedByUserC || lookedByUserC === user.id) {
            return setIsLookingOnMe(false);
        }
        setIsLookingOnMe(true);
    }, [lookedByUserC, user.id]);
    const handleLookOnMeClick = useCallback(() => {
        roomPopupsStore.findUser = lookedByUserC;
    }, [lookedByUserC]);
    return { isLookingOnMe, handleLookOnMeClick };
};
