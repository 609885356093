import { useLayoutEffect } from 'react';
const useDisableBodyScroll = () => {
    useLayoutEffect(() => {
        const originalOverflow = window.getComputedStyle(document.body).overflowY;
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = originalOverflow;
        };
    }, []);
};
export default useDisableBodyScroll;
