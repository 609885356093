import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { RequestsContainer, Heading, RequestsList, Request, RequestAction } from './styled';
import { UserName } from '../Friend/styled';
import FriendService from '@/shared/api/services/FriendService';
import { ArrowLeftRoundedIcon, SuccessIcon, CloseCircledIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { friendStore } from '@/shared/model/friends';
import { userStore } from '@/shared/model/user';
import ProfileAvatar from '@/shared/ui/ProfileAvatar';
import TextElement from '@/shared/ui/TextElement';
const FriendRequests = observer(({ onClose, friendsRequests }) => {
    const { user } = userStore;
    const { id } = user;
    const { friends } = friendStore;
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const handleApproveClick = useCallback((requesterId) => async () => {
        const response = await FriendService.approveFriendRequest(requesterId, id);
        if (!response || isAxiosError(response))
            return;
        const approvedFriends = friends.map((friend) => {
            if (friend.id === requesterId) {
                return { ...friend, ...response.data };
            }
            return friend;
        });
        friendStore.friends = approvedFriends;
    }, [friends, id]);
    const handleDeclineClick = useCallback((requesterId) => async () => {
        const response = await FriendService.declineFriendRequest(requesterId, id);
        if (!response || isAxiosError(response))
            return;
        const declinedFriends = friends.filter((friend) => friend.id !== requesterId);
        friendStore.friends = declinedFriends;
    }, [friends, id]);
    if (friendsRequests.length === 0) {
        onClose();
    }
    return (_jsxs(RequestsContainer, { children: [_jsxs(Heading, { elevation: 0, children: [_jsx(IconButton, { color: 'black', onClick: onClose, children: _jsx(ArrowLeftRoundedIcon, {}) }), _jsxs(TextElement, { fontSize: 18, fontWeight: 500, children: ["Friend Requests (", friendsRequests.length, ")"] })] }), _jsx(RequestsList, { "$isLobby": isLobby, children: friendsRequests.map(({ profilePicture, name, id: requesterId }) => (_jsxs(Request, { children: [_jsx(ProfileAvatar, { src: profilePicture, alt: name, height: 55, width: 55 }), _jsx(UserName, { color: 'darkGray', children: name }), _jsxs(RequestAction, { children: [_jsx(IconButton, { color: 'primary', "aria-label": 'Accept', onClick: handleApproveClick(requesterId), children: _jsx(SuccessIcon, { height: 24, width: 24 }) }), _jsx(IconButton, { color: 'inactive', "aria-label": 'Decline', onClick: handleDeclineClick(requesterId), children: _jsx(CloseCircledIcon, { height: 24, width: 24 }) })] })] }, requesterId))) })] }));
});
export default memo(FriendRequests);
