import { makeAutoObservable } from 'mobx';
class PendingInteractionsStore {
    _pendingNotes = [];
    _pendingInviteUserId = 0;
    _pendingRequest = null;
    _lookedByUserC = null;
    _pendingInviteFriendIds = [];
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get pendingNotes() {
        return this._pendingNotes;
    }
    get pendingInviteFriendIds() {
        return this._pendingInviteFriendIds;
    }
    set pendingInviteFriendIds(userId) {
        this._pendingInviteFriendIds = userId;
    }
    get pendingInviteUserId() {
        return this._pendingInviteUserId;
    }
    set pendingInviteUserId(userId) {
        if (this._pendingInviteUserId !== userId) {
            this._pendingInviteUserId = userId;
        }
    }
    get lookedByUserC() {
        return this._lookedByUserC;
    }
    set lookedByUserC(userId) {
        this._lookedByUserC = userId;
    }
    get pendingRequest() {
        return this._pendingRequest;
    }
    set pendingRequest(table) {
        if (this._pendingRequest !== table) {
            this._pendingRequest = table;
        }
    }
    addNote = (note) => this._pendingNotes.push({
        id: crypto.randomUUID(),
        ...note,
    });
    findNoteByUserId = (userId) => this._pendingNotes.find((note) => note.userId === userId);
    removeNoteByUserId = (userId) => {
        const removeIndex = this._pendingNotes.findIndex((interaction) => interaction.userId === userId);
        const removedItem = this._pendingNotes.at(removeIndex);
        this._pendingNotes.splice(removeIndex, 1);
        return removedItem;
    };
    shiftNote = () => this._pendingNotes.shift();
    clear = () => {
        this._pendingNotes.length = 0;
        this._pendingInviteUserId = 0;
        this._pendingRequest = null;
        this._lookedByUserC = null;
    };
}
export const pendingInteractionsStore = new PendingInteractionsStore();
