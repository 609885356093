import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { HeaderTitle } from './styled';
import { headerStore } from '@/shared/model/header';
import { roomStore } from '@/shared/model/room';
import TextElement from '@/shared/ui/TextElement';
import { ReturnToLobbyControl } from '@/features/return-to-lobby-control';
import { RoomUsersCounter } from '@/features/room-users-counter';
const RoomActions = observer(() => {
    const { room: { roomName }, } = roomStore;
    const { showControls } = headerStore;
    return roomName ? (_jsxs(_Fragment, { children: [_jsxs(HeaderTitle, { children: [_jsx(ReturnToLobbyControl, {}), showControls ? (_jsx(TextElement, { fontSize: 24, fontWeight: 600, noWrap: true, children: roomName })) : null] }), _jsx(RoomUsersCounter, {})] })) : null;
});
export default memo(RoomActions);
