import { connectionStore } from '@/shared/model/connection';
import ServiceBuilder from '@/shared/lib/utils/service';
const sendMessage = async (method, message) => ServiceBuilder.socketRequest(async () => {
    const { tableChatHubConnection } = connectionStore;
    if (!tableChatHubConnection)
        return false;
    await tableChatHubConnection.send(method, message);
    return true;
});
const TableChatHub = {
    sendMessage,
};
export default TableChatHub;
