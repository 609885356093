import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Popover, Divider, Switch } from '@mui/material';
import { memo, useState } from 'react';
import { PopoverContent, Title, Option, OptionNameContainer, OptionName } from './styled';
import { HeadingButton } from '@/entities/containers/AsideLayout';
import { SettingsIcon, VolumeOutlinedIcon } from '@/shared/assets/icons';
import { STORAGE_KEYS } from '@/shared/constants/common';
import { usePopoverAnchor } from '@/shared/lib/hooks';
import StorageService from '@/shared/lib/utils/storage';
import CustomTooltip from '@/shared/ui/CustomTooltip';
const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};
const MessagesSettings = () => {
    const { open, anchorEl, handleOpen, handleClose } = usePopoverAnchor();
    const storageItem = StorageService.get(STORAGE_KEYS.messageSound, false);
    const [checked, setChecked] = useState(storageItem ? storageItem === 'true' : true);
    const handleChange = (event) => {
        const value = event.target.checked;
        setChecked(value);
        StorageService.set(STORAGE_KEYS.messageSound, value);
    };
    return (_jsxs(_Fragment, { children: [_jsx(CustomTooltip, { title: 'Settings', placement: 'bottom', children: _jsx(HeadingButton, { onClick: handleOpen, children: _jsx(SettingsIcon, { height: 24, width: 24 }) }) }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: anchorOrigin, children: _jsxs(PopoverContent, { children: [_jsx(Title, { children: "Messages Settings" }), _jsx(Divider, { color: '#E2E2EA' }), _jsxs(Option, { children: [_jsxs(OptionNameContainer, { children: [_jsx(VolumeOutlinedIcon, {}), _jsx(OptionName, { children: "Incoming message sound" })] }), _jsx(Switch, { color: 'purple', onChange: handleChange, checked: checked })] })] }) })] }));
};
export default memo(MessagesSettings);
