import { jsx as _jsx } from "react/jsx-runtime";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { memo, useMemo } from 'react';
import { Content } from './styled';
const slots = {
    backdrop: Backdrop,
};
const TransitionedModal = ({ open, onClose, timeout = 500, children, isRemoveBg, ...props }) => {
    const slotProps = useMemo(() => ({
        backdrop: {
            timeout,
        },
    }), [timeout]);
    return (_jsx(Modal, { open: open, onClose: onClose, closeAfterTransition: true, slots: slots, slotProps: slotProps, ...props, children: _jsx(Fade, { in: open, children: _jsx(Content, { isRemoveBg: isRemoveBg, children: children }) }) }));
};
export default memo(TransitionedModal);
