export const ROUTES = {
    home: {
        path: '/',
    },
    becomeHost: {
        path: '/become-host',
    },
    ourTeam: {
        path: '/our-team',
    },
    faq: {
        path: '/faq',
    },
    services: {
        path: '/services',
    },
    about: {
        path: '/about',
    },
    features: {
        path: '/features',
    },
    events: {
        path: '/events',
        event: {
            path: '/events/:id',
        },
    },
    privacy: {
        path: '/privacy-policy',
    },
    help: {
        path: '/help',
    },
    signUp: {
        path: '/sign-up',
    },
    join: {
        path: '/join',
    },
    careers: {
        path: '/careers',
    },
    contactUs: {
        path: '/contact-us',
    },
    forgotPassword: {
        path: '/forgot-password',
        performer: {
            path: '/forgot-password/performer',
            reset: {
                path: '/forgot-password/performer/reset/:id',
            },
        },
        reset: {
            path: '/forgot-password/reset/:id',
        },
    },
    speakerDashboard: {
        path: '/speaker-dashboard',
    },
    terms: {
        path: '/terms',
    },
    unsubscribe: {
        path: '/unsubscribe/:email',
    },
    rooms: {
        path: '/rooms',
        room: {
            path: '/rooms/:roomId',
        },
    },
};
