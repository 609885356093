import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useId, useState } from 'react';
import { Grid, Link } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { BackButton, FormWrapper, EmailWrapper, PasswordWrapper, PasswordInputWrapper, EditButton, DeleteButton, Text, Title, SecurityContent, } from './styled';
import { defaultChangePasswordValues, changePasswordScheme, } from './validation';
import { changePasswordByRole } from '../../api';
import { ResetButton } from '../../styled';
import { ArrowBackIcon, LockIcon, WritingPenIcon, TrashCanIcon } from '@/shared/assets/icons';
import { userStore } from '@/shared/model/user';
import InputFormField from '@/shared/ui/InputFormField';
import SubmitButton from '@/shared/ui/SubmitButton';
import { ROUTES } from '@/shared/constants/routes';
import { ROLES } from '@/shared/constants/roles';
const SecurityForm = observer(({ setSelectedPage, onEnableClose, onDisableClose }) => {
    const { user } = userStore;
    const { email, role } = user;
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorHelper, setErrorHelper] = useState(null);
    const { handleSubmit, control, formState: { errors, isValid }, reset, } = useForm({
        defaultValues: defaultChangePasswordValues,
        resolver: zodResolver(changePasswordScheme),
    });
    const loaderId = useId();
    const onEditMode = () => {
        setIsEditMode(true);
    };
    const handleCancel = () => {
        reset();
        setIsEditMode(false);
    };
    const submit = useCallback(async ({ currentPassword, newPassword }) => {
        setErrorHelper('');
        if (!isValid)
            return;
        setLoading(true);
        onDisableClose();
        const response = await changePasswordByRole({
            role,
            email,
            previousPassword: currentPassword,
            newPassword,
        });
        if (response && !isAxiosError(response)) {
            reset();
            setIsEditMode(false);
        }
        else {
            setErrorHelper('Wrong password');
        }
        setLoading(false);
        onEnableClose();
    }, [isValid, onDisableClose, role, email, onEnableClose, reset]);
    const handleDeleteAccount = useCallback(() => setSelectedPage('Delete account'), [setSelectedPage]);
    return (_jsx(SecurityContent, { children: isEditMode ? (_jsxs(_Fragment, { children: [_jsxs(BackButton, { onClick: handleCancel, children: [_jsx(ArrowBackIcon, {}), " Back"] }), _jsx(Title, { marginBottom: '10px', children: "Change password" }), _jsx(Text, { width: '75%', height: '46px', marginBottom: 10, children: "Your new password must be different from your previous password" }), _jsx(Grid, { container: true, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', children: _jsxs(FormWrapper, { onSubmit: handleSubmit(submit), children: [_jsxs(Grid, { container: true, item: true, width: 363, children: [_jsx(Grid, { item: true, xs: 12, paddingTop: 0, children: _jsx(Controller, { name: 'currentPassword', control: control, render: ({ field: { ref, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, type: 'password', Icon: LockIcon, fullWidth: true, padding: 0, error: !!errorHelper ?? !!errors.currentPassword, autoComplete: 'current-password', placeholder: 'Current password', helperText: errorHelper ?? errors.currentPassword?.message, value: field.value, onChange: (e) => {
                                                    setErrorHelper('');
                                                    field.onChange(e);
                                                } })) }) }), _jsx(Grid, { item: true, xs: 12, paddingTop: 15, children: _jsx(Controller, { name: 'newPassword', control: control, render: ({ field: { ref, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, type: 'password', Icon: LockIcon, fullWidth: true, error: !!errors.newPassword, autoComplete: 'new-password', placeholder: 'New password', helperText: errors.newPassword?.message })) }) }), _jsx(Grid, { item: true, xs: 12, paddingTop: 15, children: _jsx(Controller, { name: 'confirmNewPassword', control: control, render: ({ field: { ref, ...field } }) => (_jsx(InputFormField, { ...field, type: 'password', inputRef: ref, Icon: LockIcon, fullWidth: true, error: !!errors.confirmNewPassword, autoComplete: 'confirm-new-password', placeholder: 'Confirm new password', helperText: errors.confirmNewPassword?.message })) }) })] }), _jsx(Grid, { item: true, xs: 12, marginTop: '30px', marginBottom: '30px', children: _jsx(Link, { href: role === ROLES.user
                                        ? ROUTES.forgotPassword.path
                                        : ROUTES.forgotPassword.performer.path, color: 'primary', underline: 'hover', target: '_blank', children: "Forgot Password?" }) }), _jsxs(Grid, { container: true, item: true, spacing: 10, xs: 10, children: [_jsx(Grid, { item: true, xs: 6, display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(SubmitButton, { width: 144, height: 48, loaderId: loaderId, loading: loading, "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, fontSize: 14, children: "Save changes" }) }), _jsx(Grid, { item: true, xs: 6, display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(ResetButton, { color: 'secondary', type: 'reset', onClick: handleCancel, children: "Cancel" }) })] })] }) })] })) : (_jsxs(_Fragment, { children: [_jsx(Title, { marginBottom: '25px', children: "Sign in" }), _jsxs(EmailWrapper, { children: [_jsx(Text, { children: "Email" }), _jsx(Text, { disabled: true, textAlign: 'right', children: email })] }), _jsxs(PasswordWrapper, { children: [_jsx(Text, { children: "Password" }), _jsxs(PasswordInputWrapper, { children: [_jsx(Text, { disabled: true, textAlign: 'right', children: "\u2217\u2217\u2217\u2217\u2217\u2217\u2217\u2217\u2217\u2217\u2217\u2217" }), _jsxs(EditButton, { onClick: onEditMode, children: [_jsx(WritingPenIcon, {}), "Edit"] })] })] }), _jsxs(DeleteButton, { onClick: handleDeleteAccount, children: [_jsx(TrashCanIcon, { color: 'red' }), " Delete account"] })] })) }));
});
export default memo(SecurityForm);
