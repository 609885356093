import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { LeaveSpeedDatingControl } from './LeaveSpeedDatingControl';
import { LeaveRoomControl } from './LeaveRoomControl';
import { roomStore } from '@/shared/model/room';
import { speedDatingStore } from '@/shared/model/speed-dating';
export const ReturnToLobbyControl = observer(() => {
    const { isMatched, isInDate } = speedDatingStore;
    const { room: { isSpeedDating }, } = roomStore;
    return isSpeedDating && (isMatched || isInDate) ? (_jsx(LeaveSpeedDatingControl, {})) : (_jsx(LeaveRoomControl, {}));
});
