import { Button, Grid, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import TextElement from '@/shared/ui/TextElement';
export const SecurityContent = styled.div `
  display: flex;
  width: 650px;
  height: 565px;
  padding: 30px 50px 35px;

  position: relative;

  flex-direction: column;
  align-items: flex-start;
`;
export const EditButton = styled(Button) `
  min-width: 46px;
  height: 23px;

  ${({ theme }) => css `
    color: ${theme.palette.primary.main};
  `}
  font-family: 'Poppins', serif;
`;
export const GridItem = styled(Grid) `
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 23px;

  .MuiButtonBase-root {
    padding: 0;
    justify-content: flex-end;
  }

  .MuiInputBase-root {
    height: 23px;
  }
`;
export const Text = styled(Typography) `
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  height: 23px;

  ${({ width }) => width
    ? css `
          width: ${width};
        `
    : css `
          width: 50%;
        `}

  ${({ height }) => height
    ? css `
          height: ${height};
        `
    : css `
          height: 23px;
        `}

  ${({ disabled }) => disabled
    ? css `
          color: #8f8f8f;
        `
    : css `
          color: #333238;
        `}
`;
export const EmailWrapper = styled.div `
  display: flex;
  width: 100%;
  margin-bottom: 30px;
`;
export const PasswordWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  height: 23px;
`;
export const PasswordInputWrapper = styled.div `
  justify-content: flex-end;
  width: 50%;
  display: flex;
  gap: 15px;
`;
export const DeleteButton = styled(Button) `
  position: absolute;
  bottom: 35px;
  right: 50px;

  padding: 6px 13px;
  gap: 10px;

  width: 179px;
  height: 48px;

  background: #ffffff;
  border-radius: 6px;

  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
`;
export const BackButton = styled(Button) `
  width: 65px;
  height: 24px;
  margin-bottom: 10px;
  color: #8f8f8f;
`;
export const FormWrapper = styled.form `
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const Title = styled(TextElement) `
  font-weight: 600;
  font-size: 17px;
  color: #333238;
  height: 26px;
  width: 100%;
`;
