import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { headerStore } from '@/shared/model/header';
import { roomStore } from '@/shared/model/room';
import TextElement from '@/shared/ui/TextElement';
export const RoomUsersCounter = observer(() => {
    const { room: { maxCapacity }, isRoomWithControls, users, } = roomStore;
    const { showControls } = headerStore;
    return users.length && maxCapacity && isRoomWithControls && showControls ? (_jsx(TextElement, { fontSize: 16, children: `${users.length}/${maxCapacity}` })) : null;
});
