import { makeAutoObservable, runInAction } from 'mobx';
import { OPPOSITE_GENDERS } from '../constants/genders';
import { MAX_AGE, MIN_AGE } from '../constants/ages';
import { STORAGE_KEYS } from '@/shared/constants/common';
import StorageService from '@/shared/lib/utils/storage';
class SpeedDatingStore {
    _selectedGender = '';
    _selectedAge = [];
    _roundEndAt = '';
    _isGoing = false;
    _roundDuration = 3;
    _secBetweenRounds = 20;
    _isParticipatingInRound = false;
    _match = null;
    _isMatched = false;
    _isClosed = true;
    _isInDate = false;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get selectedGender() {
        return this._selectedGender;
    }
    set selectedGender(selectedGender) {
        this._selectedGender = selectedGender;
    }
    get selectedAge() {
        return this._selectedAge;
    }
    set selectedAge(selectedAge) {
        this._selectedAge = selectedAge;
    }
    get roundEndAt() {
        return this._roundEndAt;
    }
    set roundEndAt(roundEndAt) {
        this._roundEndAt = roundEndAt;
    }
    get isGoing() {
        return this._isGoing;
    }
    set isGoing(isGoing) {
        this._isGoing = isGoing;
    }
    get isMatched() {
        return this._isMatched;
    }
    set isMatched(isMatched) {
        this._isMatched = isMatched;
    }
    get isClosed() {
        return this._isClosed;
    }
    set isClosed(isClosed) {
        this._isClosed = isClosed;
    }
    get roundDuration() {
        return this._roundDuration;
    }
    set roundDuration(roundDuration) {
        this._roundDuration = roundDuration;
    }
    get secBetweenRounds() {
        return this._secBetweenRounds;
    }
    set secBetweenRounds(secBetweenRounds) {
        this._secBetweenRounds = secBetweenRounds;
    }
    get isParticipatingInRound() {
        return this._isParticipatingInRound;
    }
    set isParticipatingInRound(isParticipatingInRound) {
        this._isParticipatingInRound = isParticipatingInRound;
    }
    get isInDate() {
        return this._isInDate;
    }
    set isInDate(isInDate) {
        this._isInDate = isInDate;
    }
    get match() {
        return this._match;
    }
    set match(match) {
        this._match = match;
    }
    toggleIsGoing() {
        runInAction(() => {
            this._isGoing = !this._isGoing;
        });
    }
    initFilters = (user) => {
        const filters = StorageService.get(STORAGE_KEYS.speedDatingFilters);
        if (filters) {
            this.selectedGender = filters.gender;
            this.selectedAge = filters.age;
            return;
        }
        this.selectedGender = OPPOSITE_GENDERS[user.user.gender];
        this.selectedAge = [Math.max(user.age - 5, MIN_AGE), Math.min(user.age + 5, MAX_AGE)];
    };
}
export const speedDatingStore = new SpeedDatingStore();
