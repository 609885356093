import { ListItemButton, List, Avatar } from '@mui/material';
import styled, { css } from 'styled-components';
export const Wrapper = styled.div `
  display: flex;
  justify-content: end;
  align-items: center;
  flex-shrink: 4;

  ${({ theme }) => css `
    gap: ${theme.spacing(6)};
  `}
`;
export const UserActionsWrapper = styled.div `
  display: flex;
  gap: 5px;
  height: 54px;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 90px;
`;
export const ListStyled = styled(List) `
  ${({ theme }) => css `
    & li {
      padding: 0;
    }

    & li button {
      font-size: ${theme.util.pxToRem(15)};
    }
  `}
`;
export const UserAvatar = styled(Avatar) `
  height: 46px;
  width: 46px;
  background: #ffffff;
`;
export const ItemButton = styled(ListItemButton).attrs(() => ({
    component: 'button',
})) `
  white-space: nowrap;
`;
