import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AppBar, Toolbar, LeftContainer, LogoButton } from './styled';
import RoomActions from './RoomActions';
import UserActions from './UserActions';
import { LogoIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { ViewModeControl } from '@/features/view-mode-control';
export const RoomHeader = observer(() => {
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    const isInRoom = !isLobby && !isDashboard;
    return (_jsx(AppBar, { bordered: isLobby || isDashboard, children: _jsxs(Toolbar, { children: [_jsxs(LeftContainer, { children: [(isLobby || isDashboard) && (_jsx(LogoButton, { color: 'primary', children: _jsx(LogoIcon, { color: 'primary' }) })), _jsx(RoomActions, {})] }), isInRoom && _jsx(ViewModeControl, {}), _jsx(UserActions, {})] }) }));
});
