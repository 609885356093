import { isAxiosError } from 'axios';
const serviceRequest = async (asyncCallback) => {
    try {
        const response = await asyncCallback();
        return response;
    }
    catch (error) {
        if (isAxiosError(error))
            return error;
        if (error instanceof Error) {
            console.error(error.message);
        }
        return null;
    }
};
const socketRequest = async (asyncCallback) => {
    try {
        const result = await asyncCallback();
        return result;
    }
    catch (error) {
        if (error instanceof Error) {
            console.error(error.message);
        }
        return false;
    }
};
const ServiceBuilder = {
    serviceRequest,
    socketRequest,
};
export default ServiceBuilder;
