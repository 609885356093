import { Paper } from '@mui/material';
import styled, { css } from 'styled-components';
export const FriendInviteContainer = styled(Paper) `
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  height: 71px;
  gap: 10px;
  cursor: pointer;
  ${({ $zIndex = 0 }) => css `
    z-index: ${$zIndex};
    ${$zIndex === 1
    ? css `
          top: 10px;
        `
    : null}
    ${$zIndex === 2
    ? css `
          top: 5px;
        `
    : null}
  `}

  ${({ theme }) => css `
    border: 1px solid ${theme.palette.primary.main};
  `}
`;
export const IconText = styled.div `
  display: flex;
  gap: 3px;
  align-items: center;

  ${({ paddingLeft }) => paddingLeft
    ? css `
          padding-left: ${paddingLeft};
        `
    : null}
`;
export const TextWrapper = styled.div `
  display: flex;
  align-items: center;
  max-width: 200px;
  width: 100%;
`;
export const InfoWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
