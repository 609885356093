import { memo } from 'react';
import styled, { css } from 'styled-components';
export const FriendInvitesWrapper = memo(styled.div `
  display: flex;
  width: 100%;
  min-height: 71px;
  position: relative;

  ${({ length }) => css `
    ${length === 0
    ? css `
          display: none;
        `
    : null}

    ${length === 1
    ? css `
          min-height: 76px;
        `
    : null}

    ${length >= 2
    ? css `
          min-height: 81px;
        `
    : null}
  `}
`);
