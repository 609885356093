import { makeAutoObservable } from 'mobx';
import { sendInteraction, SendInteractionsMessages } from '../../interactions';
import { userStore } from '../../user/model/store';
import { videosdkStore } from '../../videosdk/model/store';
class UserMediaStore {
    _userAudioMedia = null;
    _userVideoMedia = null;
    _userAudioTrack = null;
    _userVideoTrack = null;
    _userAudioDeviceId = null;
    _userVideoDeviceId = null;
    _userAudioActive = true;
    _userVideoActive = true;
    constructor() {
        makeAutoObservable(this);
    }
    get userAudioDeviceId() {
        return this._userAudioDeviceId;
    }
    set userAudioDeviceId(userAudioDeviceId) {
        this._userAudioDeviceId = userAudioDeviceId;
    }
    get userVideoDeviceId() {
        return this._userVideoDeviceId;
    }
    set userVideoDeviceId(userVideoDeviceId) {
        this._userVideoDeviceId = userVideoDeviceId;
    }
    get userAudioMedia() {
        return this._userAudioMedia;
    }
    set userAudioMedia(userAudioMedia) {
        this._userAudioMedia = userAudioMedia;
    }
    get userVideoMedia() {
        return this._userVideoMedia;
    }
    set userVideoMedia(userVideoMedia) {
        this._userVideoMedia = userVideoMedia;
    }
    get userAudioTrack() {
        return this._userAudioTrack;
    }
    set userAudioTrack(userAudioTrack) {
        if (this._userAudioTrack) {
            this._userAudioTrack.stop();
        }
        this._userAudioTrack = userAudioTrack;
    }
    get userVideoTrack() {
        return this._userVideoTrack;
    }
    set userVideoTrack(userVideoTrack) {
        if (this._userVideoTrack) {
            this._userVideoTrack.stop();
        }
        this._userVideoTrack = userVideoTrack;
    }
    get userAudioActive() {
        return this._userAudioActive;
    }
    set userAudioActive(isActive) {
        this._userAudioActive = isActive;
    }
    get userVideoActive() {
        return this._userVideoActive;
    }
    set userVideoActive(isActive) {
        this._userVideoActive = isActive;
    }
    toggleAudioMuted = () => {
        if (!this._userAudioTrack) {
            return;
        }
        const isEnabled = this._userAudioTrack.enabled;
        this._userAudioTrack.enabled = !isEnabled;
        videosdkStore.toggleAudio();
        sendInteraction({
            method: SendInteractionsMessages.mute,
            message: {
                userId: userStore.user.id,
                muted: isEnabled,
            },
        });
    };
    setAudioActive = (active) => {
        if (!this._userAudioTrack) {
            return;
        }
        this._userAudioTrack.enabled = active;
        this._userAudioActive = active;
        if (active) {
            videosdkStore.unmuteAudio();
        }
        else {
            videosdkStore.muteAudio();
        }
        sendInteraction({
            method: SendInteractionsMessages.mute,
            message: {
                userId: userStore.user.id,
                muted: !active,
            },
        });
    };
    toggleVideoMuted = () => {
        if (!this._userVideoTrack) {
            return;
        }
        const isEnabled = this._userVideoTrack.enabled;
        this._userVideoTrack.enabled = !isEnabled;
        videosdkStore.toggleVideo();
        sendInteraction({
            method: SendInteractionsMessages.videoMute,
            message: {
                userId: userStore.user.id,
                muted: isEnabled,
            },
        });
    };
    clear = () => {
        this.userAudioTrack = null;
        this.userVideoTrack = null;
        this._userAudioDeviceId = null;
        this._userVideoDeviceId = null;
    };
}
export const userMediaStore = new UserMediaStore();
