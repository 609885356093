import cinemaBackground from './cinema/cinema.jpg';
import libraryBackground from './library/library.jpg';
import northernBackground from './northernLights/northernLights.jpg';
import hauntedMansionBackground from './hauntedMansion/hauntedMansion.jpg';
import beachParty from './beachParty/beachParty.jpg';
import fantasyForest from './fantasyForest/fantasyForest.jpg';
import speedDating from './speedDating/speedDating.jpg';
import speedDatingMatch from './speedDating/speedDating-match.jpg';
import UpDownCinemaChair from './cinema/seatUpDown.png';
import DownUpCinemaChair from './cinema/seatDownUp.png';
import UpDownLibraryChair from './library/seatUpDown.png';
import DownUpLibraryChair from './library/seatDownUp.png';
import UpDownHauntedMansionSeat from './hauntedMansion/seatUpDown.png';
import DownUpHauntedMansionSeat from './hauntedMansion/seatDownUp.png';
import { ROOM_THEMES } from '@/shared/constants/room-themes';
const THEME_IMAGES = {
    [ROOM_THEMES.default]: { main: 'none', upDownChair: undefined, downUpChair: undefined },
    [ROOM_THEMES.cinema]: {
        main: cinemaBackground,
        upDownChair: UpDownCinemaChair,
        downUpChair: DownUpCinemaChair,
    },
    [ROOM_THEMES.library]: {
        main: libraryBackground,
        upDownChair: UpDownLibraryChair,
        downUpChair: DownUpLibraryChair,
    },
    [ROOM_THEMES.northernLights]: {
        main: northernBackground,
        upDownChair: undefined,
        downUpChair: undefined,
    },
    [ROOM_THEMES.hauntedMansion]: {
        main: hauntedMansionBackground,
        upDownChair: UpDownHauntedMansionSeat,
        downUpChair: DownUpHauntedMansionSeat,
    },
    [ROOM_THEMES.beachParty]: {
        main: beachParty,
        upDownChair: undefined,
        downUpChair: undefined,
    },
    [ROOM_THEMES.fantasyForest]: {
        main: fantasyForest,
        upDownChair: undefined,
        downUpChair: undefined,
    },
    [ROOM_THEMES.speedDating]: {
        main: speedDating,
        upDownChair: undefined,
        downUpChair: undefined,
    },
    [ROOM_THEMES.speedDatingMatch]: {
        main: speedDatingMatch,
        upDownChair: undefined,
        downUpChair: undefined,
    },
};
export const THEMES_WITH_CHAIRS = Object.keys(THEME_IMAGES).filter((k) => THEME_IMAGES[k].downUpChair && THEME_IMAGES[k].upDownChair);
export default THEME_IMAGES;
