import { Avatar, List } from '@mui/material';
import styled, { css } from 'styled-components';
export const NoResultWrapper = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  margin-top: 80px;
  color: #292d32;
`;
const Chat = styled.li `
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  //padding: 8px 0 8px 8px;
  padding: 8px;
  border-radius: 8px;
  gap: 10px;
  cursor: pointer;
`;
export const TableChatContainer = styled(Chat) `
  background-color: #f0ecff;
  border-radius: 8px;
  max-height: 75px;

  flex: auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  padding-right: 8px;
`;
export const SubTitle = styled.p `
  padding-left: 10px;

  margin: 0;

  ${({ theme }) => css `
    color: inherit;
    font-size: ${theme.util.pxToRem(18)};
  `}
`;
export const MessageCounter = styled.span `
  ${({ theme }) => css `
    color: ${theme.palette.white.main};
    background-color: ${theme.palette.primary.main};
    height: 14px;
    width: 14px;
    border-radius: 50%;

    font-size: ${theme.util.pxToRem(10)};
  `}
`;
export const OnlineStatus = styled.span `
  position: absolute;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  border: 3px solid white;
  height: 15px;
  width: 15px;
  ${({ theme, status }) => css `
    background-color: ${theme.palette[status].main};
  `}
`;
export const UserContainer = styled.div `
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  & > .MuiAvatar-root {
    display: flex;
    justify-content: center;
  }
`;
export const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
`;
export const ProfilePicture = styled(Avatar) `
  height: 55px;
  width: 55px;
`;
export const UserName = styled.p `
  margin: 0;
  padding: 0;
  ${({ theme, color }) => css `
    color: ${theme.palette[color].main};
    font-size: ${theme.util.pxToRem(16)};
    font-weight: 400;
  `}
`;
export const UserMessage = styled.p `
  max-width: 200px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme, isUnread }) => isUnread
    ? css `
          color: ${theme.palette.purple.main};
          font-size: ${theme.util.pxToRem(14)};
          font-weight: 400;
        `
    : css `
          color: #8f8f8f;
          font-size: ${theme.util.pxToRem(14)};
          font-weight: 400;
        `}
`;
export const ChatInfo = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
  margin-left: auto;
`;
export const Time = styled.p `
  padding: 3px;

  width: 100%;

  ${({ theme }) => css `
    color: ${theme.palette.inactive.main};
    font-size: ${theme.util.pxToRem(12)};
  `}
`;
export const FriendChatContainer = styled(Chat) `
  & .MuiIconButton-root {
    display: none;
  }

  &:hover {
    background-color: #f3f2fa;

    & ${ChatInfo} {
      top: 20px;
    }

    & .MuiIconButton-root {
      display: block;
      margin-top: 3px;
    }

    & ${Time}, & ${MessageCounter} {
      display: none;
    }
  }

  height: 71px;
`;
export const FriendsChatList = styled(List) `
  overflow-y: auto;
  border-radius: 0;
  position: relative;
  width: 100%;

  margin-right: -5px;

  flex-grow: 1;
  padding-right: 8px;

  ${({ $isLobby }) => css `
    ${$isLobby
    ? css `
          &::-webkit-scrollbar-thumb {
            ${({ theme }) => css `
              border-radius: 5px;
              background: ${theme.palette.primary.main};
            `}
          }

          &::-webkit-scrollbar-track {
            background: #e2e2e2;
          }
        `
    : css `
          &::-webkit-scrollbar-thumb {
            background: #a2a2bf;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }
        `}
  `}

  &::-webkit-scrollbar {
    width: 5px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: 0px 20px 70px rgba(55, 69, 87, 0.3);
    border-right: none;
    border-left: none;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    margin-bottom: 10px;
  }
`;
