import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { StreamControls } from './styled';
import { headerStore } from '@/shared/model/header';
import { CameraControl } from '@/features/camera-control';
import { MicrophoneControl } from '@/features/microphone-control';
import { LookOnMeControl } from '@/features/look-on-me-control';
export const UserStreamControls = observer(() => {
    const { showCameraControl } = headerStore;
    return (_jsxs(StreamControls, { children: [_jsx(LookOnMeControl, {}), showCameraControl && _jsx(CameraControl, {}), _jsx(MicrophoneControl, {})] }));
});
