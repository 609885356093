import { useEffect } from 'react';
import { connectionStore } from '@/shared/model/connection';
import { useEvent } from '@/shared/lib/hooks';
export const useReceiveInteraction = ({ method, callback }) => {
    const savedCallback = useEvent(callback);
    const { interactionHubConnection } = connectionStore;
    useEffect(() => {
        interactionHubConnection?.on(method, (hubMessage) => {
            savedCallback(hubMessage);
        });
        return () => {
            interactionHubConnection?.off(method);
        };
    }, [method, interactionHubConnection, savedCallback]);
};
