export const GENDERS = [
    {
        value: 'Male',
        label: 'Men',
    },
    {
        value: 'Female',
        label: 'Women',
    },
    {
        value: 'Any',
        label: 'Any',
    },
];
export const OPPOSITE_GENDERS = {
    Male: 'Women',
    Female: 'Men',
    Other: 'Any',
};
