import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, differenceInWeeks, format, parse, parseISO, } from 'date-fns';
// time string in format "HH:mm" to "HH:MM AM/PM" from Israel time to local time
const toAMPM = (timeString) => {
    const israelTime = parse(timeString, 'HH:mm', new Date());
    const utcTime = zonedTimeToUtc(israelTime, 'Asia/Jerusalem');
    const localTime = utcToZonedTime(utcTime, Intl.DateTimeFormat().resolvedOptions().timeZone);
    return format(localTime, 'hh a');
};
const convertOffsetToTimeDifference = (offsetString) => {
    if (!offsetString)
        return '';
    // Parse the DateTime Offset string
    const utcTime = parseISO(offsetString);
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime = utcToZonedTime(utcTime, localTimeZone);
    const now = new Date();
    const secondsDifference = differenceInSeconds(now, localTime);
    if (secondsDifference < 60) {
        // if (secondsDifference === 0) return `Now`;
        return `1m`;
    }
    if (secondsDifference < 3600) {
        const minutesDifference = differenceInMinutes(now, localTime);
        return `${minutesDifference}m`;
    }
    if (secondsDifference < 86400) {
        const hoursDifference = differenceInHours(now, localTime);
        return `${hoursDifference}h`;
    }
    if (secondsDifference < 604800) {
        const daysDifference = differenceInDays(now, localTime);
        return `${daysDifference}d`;
    }
    const weeksDifference = differenceInWeeks(now, localTime);
    return `${weeksDifference}w`;
};
const hourDiff = (date1, date2) => Math.floor((date1.getTime() - date2.getTime()) / 36e5);
const parseSecondsToTimer = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${min}:${sec.toString().padStart(2, '0')}`;
};
const toLocalDate = (dateString, dateFormat = "yyyy-M-dd'T'HH:mm:ss") => {
    const israelDate = parse(dateString, dateFormat, new Date());
    const utcDate = zonedTimeToUtc(israelDate, 'Asia/Jerusalem');
    const localDate = utcToZonedTime(utcDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
    return localDate;
};
const toLocalTime = (timeString) => {
    const israelTime = parse(timeString, 'HH:mm', new Date());
    const utcTime = zonedTimeToUtc(israelTime, 'Asia/Jerusalem');
    const localTime = utcToZonedTime(utcTime, Intl.DateTimeFormat().resolvedOptions().timeZone);
    return format(localTime, 'HH:mm');
};
const DateService = {
    convertOffsetToTimeDifference,
    toAMPM,
    hourDiff,
    parseSecondsToTimer,
    toLocalDate,
    toLocalTime,
};
export default DateService;
