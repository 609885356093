import { makeAutoObservable } from 'mobx';
import { twinkleSparkleSound } from '@/shared/assets/sounds';
class PendingNotificationsStore {
    audio = new Audio(twinkleSparkleSound);
    _pendingNotifications = [];
    _notificationOnCheck = null;
    _isNotificationCheckOutOfQue = false;
    _added = false;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get pendingNotifications() {
        return this._pendingNotifications;
    }
    get interactionsNotifications() {
        return this._pendingNotifications.filter((pN) => pN.popup.type === 'inviter' || pN.popup.type === 'requester');
    }
    get notificationOnCheck() {
        return this._notificationOnCheck;
    }
    set notificationOnCheck(notificationOnCheck) {
        this._notificationOnCheck = notificationOnCheck;
    }
    get notesNotifications() {
        return this._pendingNotifications.filter((pN) => pN.popup.type === 'noteSender');
    }
    set added(added) {
        this._added = added;
    }
    get added() {
        return this._added;
    }
    set isNotificationCheckOutOfQue(isNotificationCheckOutOfQue) {
        this._isNotificationCheckOutOfQue = isNotificationCheckOutOfQue;
    }
    get isNotificationCheckOutOfQue() {
        return this._isNotificationCheckOutOfQue;
    }
    findByReceiverId = (receiverId) => this._pendingNotifications.find(({ popup }) => popup.receiverId === receiverId);
    add = (pendingPopup) => {
        // const alreadyExists = !!this._pendingNotifications.find(
        //   ({ popup }) =>
        //     popup.type === pendingPopup.popup.type &&
        //     popup.receiverId === pendingPopup.popup.receiverId,
        // );
        // if (alreadyExists) {
        //   return this._pendingNotifications.length;
        // }
        this.audio.play();
        this._added = true;
        return this._pendingNotifications.push(pendingPopup);
    };
    showNextNotification = () => {
        this._pendingNotifications = this._pendingNotifications
            .splice(-1)
            .concat(this._pendingNotifications);
    };
    shiftNotificationByUserId = (type, userId) => {
        let interactions = this.interactionsNotifications;
        let notes = this.notesNotifications;
        if (type === 'interaction') {
            interactions = interactions.filter((i) => i.popup.receiverId !== userId);
        }
        if (type === 'note') {
            notes = notes.filter((i) => i.popup.receiverId !== userId);
        }
        this._pendingNotifications = [...interactions, ...notes];
    };
    shiftFirstNotification = (type) => {
        const interactions = this.interactionsNotifications;
        const notes = this.notesNotifications;
        if (type === 'interaction') {
            interactions.shift();
        }
        if (type === 'note') {
            notes.shift();
        }
        this._pendingNotifications = [...interactions, ...notes];
    };
    shiftPending = (type, userId) => {
        if (!userId) {
            this.shiftFirstNotification(type);
            return;
        }
        this.shiftNotificationByUserId(type, userId);
    };
    removePendingRequests = () => {
        this._pendingNotifications = this._pendingNotifications.filter(({ popup }) => popup.type !== 'requester');
    };
    clear = () => {
        this._pendingNotifications.length = 0;
    };
}
const pendingNotificationsStore = new PendingNotificationsStore();
export default pendingNotificationsStore;
