import { useState, useEffect, useMemo } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { HUB_METHODS } from '@/shared/constants/hub';
import { connectionStore } from '@/shared/model/connection';
import { userStore } from '@/shared/model/user';
import { headerStore } from '@/shared/model/header';
import { useTableChatHubReceive } from '@/shared/lib/hooks';
import { CHAT_USER_COLORS } from '@/shared/constants/common';
const useTableChatConnection = (isTableChat) => {
    const { user, userTableId, userTableName } = userStore;
    const { tableChatHubConnection } = connectionStore;
    const [connected, setConnected] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [chatClients, setChatClients] = useState([]);
    const [newMessageReceived, setNewMessageReceived] = useState(false);
    useEffect(() => {
        if (userTableName === 'Bar') {
            (async () => {
                await connectionStore.tableChatHubConnection?.stop();
                headerStore.cleanUnreadMessageCount();
                setChatHistory([]);
            })();
        }
    }, [userTableName]);
    useEffect(() => {
        if (!user.id || !userTableId || userTableName === 'Bar')
            return;
        (async () => {
            await connectionStore.tableChatHubConnection?.stop();
            setChatHistory([]);
        })();
        connectionStore.connectUserToTableChat(user.id, userTableId);
        connectionStore.tableChatHubConnection?.start();
    }, [user.id, userTableId, userTableName]);
    useEffect(() => {
        if (tableChatHubConnection?.state === HubConnectionState.Disconnected) {
            setConnected(false);
            return;
        }
        if (tableChatHubConnection?.state === HubConnectionState.Connected) {
            setConnected(true);
        }
    }, [tableChatHubConnection, tableChatHubConnection?.state]);
    useTableChatHubReceive(HUB_METHODS.table.receive.message, ({ clients, chatMessage }) => {
        if (chatMessage) {
            headerStore.chatMessageReceived = true;
            if (!isTableChat) {
                headerStore.increaseUnreadMessageCount();
            }
            setChatHistory((prevHistory) => [...prevHistory, chatMessage]);
            setNewMessageReceived(true);
        }
        if (Array.isArray(clients)) {
            setChatClients(clients);
        }
    });
    useEffect(() => {
        if (isTableChat) {
            headerStore.cleanUnreadMessageCount();
        }
    }, [isTableChat]);
    useEffect(() => {
        if (newMessageReceived && isTableChat) {
            setNewMessageReceived(false);
        }
    }, [isTableChat, newMessageReceived]);
    const usernameColorScheme = useMemo(() => {
        const usernames = chatClients.map(({ username }) => username);
        return usernames.reduce((acc, curr, index) => {
            acc[curr] = CHAT_USER_COLORS[index];
            return acc;
        }, {});
    }, [chatClients]);
    return { connected, chatHistory, usernameColorScheme, newMessageReceived };
};
export default useTableChatConnection;
