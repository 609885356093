export var SendInteractionsMessages;
(function (SendInteractionsMessages) {
    SendInteractionsMessages["message"] = "SendMessage";
    SendInteractionsMessages["sendUpdateStreamId"] = "SendUpdateStreamId";
    SendInteractionsMessages["inviteToTable"] = "InviteToTable";
    SendInteractionsMessages["requestToJoin"] = "RequestToJoin";
    SendInteractionsMessages["approveInviteToTable"] = "ApproveInviteToTable";
    SendInteractionsMessages["declineInviteToTable"] = "DeclineInviteToTable";
    SendInteractionsMessages["declineRequestToJoin"] = "DeclineRequestToJoin";
    SendInteractionsMessages["approveRequestToJoin"] = "ApproveRequestToJoin";
    SendInteractionsMessages["checkNotificationStatus"] = "CheckNotificationStatus";
    SendInteractionsMessages["cancelInteraction"] = "CancelInteraction";
    SendInteractionsMessages["leaveTable"] = "LeaveFromTable";
    SendInteractionsMessages["friendRequest"] = "SendFriendRequest";
    SendInteractionsMessages["mute"] = "SendMuteAlert";
    SendInteractionsMessages["videoMute"] = "SendVideoMuteAlert";
    SendInteractionsMessages["moveToSeat"] = "MoveToSeat";
    SendInteractionsMessages["lookAtUser"] = "LookAtUser";
    SendInteractionsMessages["passNote"] = "PassNote";
    SendInteractionsMessages["requestToJoinStage"] = "RequestToJoinStage";
    SendInteractionsMessages["cancelRequestToJoinStage"] = "CancelRequestToJoinStage";
    SendInteractionsMessages["approveRequestToJoinStage"] = "ApproveRequestToJoinStage";
    SendInteractionsMessages["rejectRequestToJoinStage"] = "RejectRequestToJoinStage";
    SendInteractionsMessages["kickUserFromStage"] = "KickUserFromStage";
    SendInteractionsMessages["leaveFromStage"] = "LeaveFromStage";
    SendInteractionsMessages["setSpeedDatingFilters"] = "SetSpeedDatingRoomFilters";
    SendInteractionsMessages["speedDatingLike"] = "SpeedDatingLike";
    SendInteractionsMessages["speedDatingUndoLike"] = "SpeedDatingUndoLike";
    SendInteractionsMessages["setUserAnswer"] = "SetUserAnswer";
    SendInteractionsMessages["newStreamId"] = "SetNewStreamId";
    SendInteractionsMessages["returnToSpeedDating"] = "ReturnToSpeedDating";
    SendInteractionsMessages["setUserMix"] = "SetUserMix";
    SendInteractionsMessages["incorrectStreamId"] = "IncorrectStreamId";
    SendInteractionsMessages["gotFixStreamId"] = "GotFixStreamId";
})(SendInteractionsMessages || (SendInteractionsMessages = {}));
