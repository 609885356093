import styled, { css } from 'styled-components';
export const UncheckedRequests = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -2px;
  height: 22px;
  border-radius: 1rem;

  ${({ theme, isMax }) => css `
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.main};
    font-size: ${theme.util.pxToRem(14)};

    right: ${isMax ? '-15px' : '-2px'};
    width: ${isMax ? '35px' : '22px'};
  `}
`;
