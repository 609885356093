import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { LobbyIcon } from '@/shared/assets/icons';
import { modalStore } from '@/shared/model/modal';
import ChangeTable from '@/entities/notifications/ChangeTable';
import CustomTooltip from '@/shared/ui/CustomTooltip';
import { speedDatingStore } from '@/shared/model/speed-dating';
import { sendInteraction, SendInteractionsMessages } from '@/shared/model/interactions';
export const LeaveSpeedDatingControl = observer(() => {
    const handleLeaveSpeedDatingMatch = useCallback(() => {
        sendInteraction({
            method: SendInteractionsMessages.returnToSpeedDating,
        });
        speedDatingStore.isMatched = false;
    }, []);
    const handleReturnToSpeedDatingQueueClick = useCallback(() => {
        modalStore.open({
            content: (_jsx(ChangeTable, { title: 'Leave date?', confirmLabel: 'Yes, leave date', declineLabel: 'No, stay', buttonsFullWidth: true, onSubmit: handleLeaveSpeedDatingMatch, message: 'Are you sure that you want to leave this date?' })),
        });
    }, [handleLeaveSpeedDatingMatch]);
    return (_jsx(CustomTooltip, { title: 'Return to speed dating queue', placement: 'bottom', children: _jsx(IconButton, { color: 'white', onClick: handleReturnToSpeedDatingQueueClick, "aria-label": 'Return to lobby', children: _jsx(LobbyIcon, { width: 27, height: 18 }) }) }));
});
