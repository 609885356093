import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { BackgroundTheme, Logo, LeftSideStuff, Stuff, RightSideStuff } from './styled';
import useRoomTheme from './lib/hooks/useRoomTheme';
import { roomStore } from '@/shared/model/room';
import { ROUTES } from '@/shared/constants/routes';
import { speedDatingStore } from '@/shared/model/speed-dating';
const RoomTheme = observer(() => {
    const { pathname } = useLocation();
    const roomTheme = useRoomTheme();
    const { room: { theme, isSpeedDating }, } = roomStore;
    const { isMatched } = speedDatingStore;
    if (pathname.startsWith(`${ROUTES.rooms.path}/`)) {
        return (_jsxs(_Fragment, { children: [roomTheme.logo && !isSpeedDating ? (_jsx(Logo, { src: roomTheme.logo, left: roomTheme.coords.logo.left, bottom: roomTheme.coords.logo.bottom, width: roomTheme.size.logo.width })) : null, roomTheme.leftSide && !isMatched ? (_jsx(LeftSideStuff, { src: roomTheme.leftSide, top: roomTheme.coords.leftSide.top, left: roomTheme.coords.leftSide.left, width: roomTheme.size.leftSide.width, height: roomTheme.size.leftSide.height, role: 'presentation' })) : null, roomTheme.rightSide ? (_jsx(RightSideStuff, { src: roomTheme.rightSide, top: roomTheme.coords.rightSide.top, right: roomTheme.coords.rightSide.right, height: roomTheme.size.rightSide.height, width: roomTheme.size.rightSide.width, role: 'presentation' })) : null, roomTheme.stuff && !isSpeedDating
                    ? roomTheme.coords.stuff.map((position, index) => (_jsx(Stuff, { src: roomTheme.stuff, top: position.top, left: position.left, bottom: position.bottom, right: position.right, width: roomTheme.size.stuff[index].width, role: 'presentation' }, position.id)))
                    : null, _jsx(BackgroundTheme, { themeName: theme, isSpeedDating: isSpeedDating, isMatched: isMatched })] }));
    }
    return null;
});
export default memo(RoomTheme);
