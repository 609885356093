import styled from 'styled-components';
import { Popper, Tooltip } from '@mui/material';
import { StageVolumeIcon } from '@/shared/assets/icons';
export const VolumeTooltip = styled(Tooltip) ``;
export const VolumeTooltipPopper = styled(Popper) `
  & .MuiTooltip-arrow {
    color: white;
  }

  & .MuiTooltip-tooltip {
    padding: 16px 16px 4px 16px;
    background-color: white;
  }
`;
export const VolumeSliderWrapper = styled.div `
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: black;
`;
export const VolumeIcon = styled(StageVolumeIcon) `
  fill: transparent;
  color: white;
  cursor: pointer;
`;
