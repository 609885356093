import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo, useCallback } from 'react';
import { modalStore } from '@/shared/model/modal';
import TransitionedModal from '@/shared/ui/TransitionedModal';
const Modals = observer(() => {
    const { props, loadingState } = modalStore;
    const { content, onClose, ...modalProps } = props;
    const handleClose = useCallback((event, reason) => {
        if (onClose) {
            onClose(event, reason);
            return;
        }
        if (loadingState && (reason === 'escapeKeyDown' || reason === 'backdropClick'))
            return;
        modalStore.close();
    }, [loadingState, onClose]);
    return (_jsx(TransitionedModal, { onClose: handleClose, ...modalProps, children: content }));
});
export default memo(Modals);
