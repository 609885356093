import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { InputAdornment } from '@mui/material';
import { memo } from 'react';
import { CloseButton, SearchBarStyled } from './styled';
import { CloseIcon, SearchIcon } from '@/shared/assets/icons';
const SearchBar = ({ value, onClear, ...props }) => (_jsx(SearchBarStyled, { fullWidth: true, placeholder: 'Search', variant: 'outlined', InputProps: {
        startAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(SearchIcon, {}) })),
        endAdornment: value && onClear ? (_jsx(InputAdornment, { position: 'end', children: _jsx(CloseButton, { onClick: onClear, children: _jsx(CloseIcon, {}) }) })) : (_jsx(_Fragment, {})),
    }, value: value, ...props }));
export default memo(SearchBar);
