import { FacebookIcon, InstagramOutlinedIcon, LinkedInIcon } from '@/shared/assets/icons';
import { SOCIAL_MEDIA } from '@/shared/constants/common';
import { ROUTES } from '@/shared/constants/routes';
export const paths = [
    {
        label: 'Events',
        route: ROUTES.events.path,
    },
    {
        label: 'Become a host',
        route: ROUTES.becomeHost.path,
    },
    // {
    //   label: 'Our Team',
    //   route: ROUTES.ourTeam.path,
    // },
];
export const socials = [
    {
        href: SOCIAL_MEDIA.facebook,
        Icon: FacebookIcon,
    },
    {
        href: SOCIAL_MEDIA.instagram,
        Icon: InstagramOutlinedIcon,
    },
    {
        href: SOCIAL_MEDIA.linkedin,
        Icon: LinkedInIcon,
    },
];
