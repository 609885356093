import { makeAutoObservable } from 'mobx';
class FriendStore {
    _friends = [];
    _newFriends = [];
    _activeFriendChatId = 0;
    _activeChatHistory = [];
    _invites = [];
    _enterFromInvite = false;
    _pendingInvites = [];
    _inviteSentById = 0;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    set friends(friendsList) {
        this.newFriends = [
            ...this.newFriends,
            ...friendsList.filter((f) => f.unread && !this.friends.some((fr) => fr.id === f.id)),
        ];
        this._friends = friendsList;
    }
    get friends() {
        return this._friends;
    }
    set newFriends(friendsList) {
        this._newFriends = friendsList;
    }
    get newFriends() {
        return this._newFriends;
    }
    set activeFriendChatId(id) {
        this._activeFriendChatId = id;
    }
    get activeFriendChatId() {
        return this._activeFriendChatId;
    }
    set activeChatHistory(friendsList) {
        this._activeChatHistory = friendsList;
    }
    get activeChatHistory() {
        return this._activeChatHistory;
    }
    set invites(invites) {
        this._invites = invites;
    }
    get invites() {
        return this._invites;
    }
    get pendingInvites() {
        return this._pendingInvites;
    }
    addPendingInvite(userId) {
        this._pendingInvites.push(userId);
    }
    removePendingInvite(userId) {
        this._pendingInvites = this._pendingInvites.filter((i) => i !== userId);
    }
    removeAllPendingInvite() {
        this._pendingInvites = [];
    }
    set enterFromInvite(state) {
        this._enterFromInvite = state;
    }
    get enterFromInvite() {
        return this._enterFromInvite;
    }
    set inviteSentById(id) {
        this._inviteSentById = id;
    }
    get inviteSentById() {
        return this._inviteSentById;
    }
    clearInvites = () => {
        this._inviteSentById = 0;
        this._enterFromInvite = false;
    };
    clear = () => {
        this._friends = [];
        this._activeFriendChatId = 0;
        this._activeChatHistory = [];
        this._invites = [];
    };
}
export const friendStore = new FriendStore();
