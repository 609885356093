/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useInsertionEffect, useRef } from 'react';
const useEvent = (fn, deps = []) => {
    const fnRef = useRef(fn);
    useInsertionEffect(() => {
        fnRef.current = fn;
    }, [fn]);
    const eventCb = useCallback((...args) => fnRef.current.apply(null, args), deps);
    return eventCb;
};
export default useEvent;
