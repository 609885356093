import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';
import { memo } from 'react';
export const ProfileAvatarStyled = memo(styled(Avatar) `
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $width, $height, $borderColor, $borderStyle = 'solid', $borderWidth = '1px', $outlineColor, $outlineStyle = 'solid', $outlineWidth = '1px', $objectFit, }) => css `
    width: ${typeof $width === 'number' ? `${$width}px` : $width};
    height: ${typeof $height === 'number' ? `${$height}px` : $height};
    ${$borderColor
    ? css `
          border: ${$borderWidth} ${$borderStyle} ${$borderColor};
        `
    : null}

    ${$outlineColor
    ? css `
          outline: ${$outlineWidth} ${$outlineStyle} ${$outlineColor};
        `
    : null}

    ${$objectFit
    ? css `
          object-fit: ${$objectFit};
        `
    : null}
  `}
`);
