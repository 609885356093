import styled, { css } from 'styled-components';
export const PopoverContent = styled.div `
  display: flex;
  flex-direction: column;
  padding: 9px;
  gap: 7px;
  min-width: 366px;
`;
export const Title = styled.p `
  ${({ theme }) => css `
    color: ${theme.palette.black.main};
    font-size: ${theme.util.pxToRem(17)};
    font-weight: 500;
  `}
`;
export const Option = styled.div `
  margin: -5px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & .MuiSvgIcon-root {
    color: #8f8f8f;
  }
`;
export const OptionNameContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const OptionName = styled.p `
  padding: 2px 0;
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(14)};
  `}
`;
