import { Slider, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import TextElement from '@/shared/ui/TextElement';
import { StageVolumeIcon, StageVolumeMutedIcon } from '@/shared/assets/icons';
export const AdministrationFormContent = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px 50px 35px;

  position: relative;

  flex-direction: column;
  align-items: flex-start;
`;
export const Text = styled(Typography) `
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  height: 23px;

  ${({ width }) => width
    ? css `
          width: ${width};
        `
    : css `
          width: 50%;
        `}

  ${({ height }) => height
    ? css `
          height: ${height};
        `
    : css `
          height: 23px;
        `}

  ${({ disabled }) => disabled
    ? css `
          color: #8f8f8f;
        `
    : css `
          color: #333238;
        `}
`;
export const Title = styled(TextElement) `
  font-weight: 600;
  font-size: 17px;
  color: #333238;
  height: 26px;
  width: 100%;
`;
export const BackgroundMusicSliderWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
`;
export const BackgroundMusicVolumeSlider = styled(Slider) `
  &.MuiSlider-root {
    width: 175px;
    height: 6px;

    .MuiSlider-rail {
      background-color: #ededed;
    }

    .MuiSlider-thumb {
      width: 16px;
      height: 16px;
    }

    .MuiSlider-track {
      background-color: #dbb8ff;
    }
  }
`;
export const VolumeMutedIcon = styled(StageVolumeMutedIcon) `
  fill: transparent;
  color: #292d32;
`;
export const VolumeIcon = styled(StageVolumeIcon) `
  fill: transparent;
  color: #292d32;
`;
