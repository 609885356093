import { object, string } from 'zod';
export const profileScheme = object({
    userName: string({
        required_error: 'Must not be empty',
    })
        .min(1, 'This field must be between 1-20 characters')
        .max(20, 'This field must be between 1-20 characters')
        .refine((value) => /^[\p{L}\u0590-\u05fe]+(\s?[\p{L}\u0590-\u05fe]+)*$/giu.test(value), 'Name should contain only alphabets'),
    country: string({
        required_error: 'Must not be empty',
    }),
    gender: string({
        required_error: 'Must not be empty',
    }),
    interestedIn: string({
        required_error: 'Must not be empty',
    }),
});
