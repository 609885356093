import { object, string } from 'zod';
export const changePasswordScheme = object({
    currentPassword: string({
        required_error: 'Required field',
    })
        .min(8, 'Password must be more then 8 characters')
        .max(25, 'Password must be less then 25 characters'),
    newPassword: string({
        required_error: 'Required field',
    })
        .min(8, 'Password must be more then 8 characters')
        .max(25, 'Password must be less then 25 characters'),
    confirmNewPassword: string({
        required_error: 'Required field',
    })
        .min(8, 'Password must be more then 8 characters')
        .max(25, 'Password must be less then 25 characters'),
}).refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    message: 'Passwords are not matching',
});
export const defaultChangePasswordValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
};
