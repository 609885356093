import { IconButton, ListItemButton, TextField } from '@mui/material';
import styled, { css } from 'styled-components';
export const Form = styled.form `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 495px;
  padding: 32px;
  align-items: center;
  justify-content: normal;
  gap: 24px;
`;
export const ReportReasonsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
export const ReportReason = styled(ListItemButton) `
  padding: 8px 12px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border: 1px solid #d4d2d5;
  cursor: pointer;
  width: 100%;

  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(16)};

    &:hover,
    &.selected {
      border-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.main};
    }
  `}
`;
export const DescriptionField = styled(TextField) `
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    margin-right: 2px;
    margin-bottom: 5px;

    ${({ theme, $isMaxLength }) => $isMaxLength
    ? css `
            color: ${theme.palette.black.main};
            font-weight: bolder;
            font-size: ${theme.util.pxToRem(14)};
          `
    : null}
  }

  & .MuiInputBase-root {
    background: #e2e2ea;
    padding: 5px;
    padding-right: 40px;

    ${({ theme }) => css `
      font-size: ${theme.util.pxToRem(16)};
    `}

    & textarea,
    & input {
      ${({ theme }) => css `
        font-size: ${theme.util.pxToRem(16)};
      `}
    }

    & fieldset {
      border: none;
      outline: none;

      &:hover,
      &:focus {
        border: none;
        outline: none;
      }
    }

    &::placeholder {
      color: #7e7a93;
    }

    &:focus-within,
    &:hover {
      ${({ theme }) => css `
        outline: 1px solid ${theme.palette.purple.main};
      `}
      box-shadow: 0px 0px 8px -1px rgba(105, 69, 250, 0.5);
    }

    &:focus-within {
      background: transparent;
    }
  }
`;
export const CloseButton = styled(IconButton) `
  position: absolute;
  top: 20px;
  right: 20px;

  & .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }
`;
