import { object, string } from 'zod';
export const registerScheme = object({
    email: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .email('Email is not valid')
        .transform((value) => value.trim()),
    password: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .min(8, 'Password must be more then 8 characters')
        .max(25, 'Password must be less then 25 characters')
        .transform((value) => value.trim()),
});
export const defaultValues = {
    email: '',
    password: '',
};
