import CinemaLogo from '../assets/images/cinema/logo.png';
import CinemaStuff from '../assets/images/cinema/stuff.png';
import CinemaLeftSide from '../assets/images/cinema/leftSideStuff.png';
import FantasyForestStuff from '../assets/images/fantasyForest/stuff.png';
import HauntedMansionLogo from '../assets/images/hauntedMansion/logo.png';
import HauntedMansionLeftSide from '../assets/images/hauntedMansion/leftSideStuff.png';
import HauntedMansionRightSide from '../assets/images/hauntedMansion/rightSideStuff.png';
export const logos = {
    default: '',
    cinema: CinemaLogo,
    hauntedMansion: HauntedMansionLogo,
    fantasyForest: '',
    library: '',
    northernLights: '',
};
export const stuffs = {
    default: '',
    cinema: CinemaStuff,
    fantasyForest: FantasyForestStuff,
    hauntedMansion: '',
    library: '',
    northernLights: '',
};
export const rightSide = {
    default: '',
    cinema: '',
    hauntedMansion: HauntedMansionRightSide,
    fantasyForest: FantasyForestStuff,
    library: '',
    northernLights: '',
};
export const leftSide = {
    default: '',
    cinema: CinemaLeftSide,
    hauntedMansion: HauntedMansionLeftSide,
    fantasyForest: '',
    library: '',
    northernLights: '',
};
export const coords = {
    default: null,
    cinema: {
        logo: { top: null, left: 0, right: null, bottom: 0 },
        stuff: [
            { id: 1, top: null, left: null, right: 20, bottom: 30 },
            { id: 2, top: null, left: null, right: 45, bottom: 20 },
            { id: 3, top: null, left: null, right: 30, bottom: 15 },
        ],
        rightSide: { top: 70, left: null, right: 40, bottom: null },
        leftSide: { top: 51, left: 20, right: null, bottom: null },
    },
    hauntedMansion: {
        logo: { top: null, left: -142, right: null, bottom: -106 },
        stuff: [{ id: 1, top: null, left: null, right: null, bottom: null }],
        rightSide: { top: 64, left: null, right: 46, bottom: null },
        leftSide: { top: 31, left: 20, right: null, bottom: null },
    },
    fantasyForest: {
        logo: { top: null, left: null, right: null, bottom: null },
        stuff: [
            {
                id: 1,
                top: null,
                left: -63,
                right: null,
                bottom: -8,
            },
            {
                id: 2,
                top: null,
                left: 333,
                right: null,
                bottom: 60,
            },
            {
                id: 3,
                top: 4,
                left: 988,
                right: null,
                bottom: null,
            },
            {
                id: 4,
                top: 67,
                left: 692,
                right: null,
                bottom: null,
            },
        ],
        rightSide: { top: 396, left: null, right: 0, bottom: null },
        leftSide: { top: null, left: null, right: null, bottom: null },
    },
    library: null,
    northernLights: null,
};
export const size = {
    default: null,
    cinema: {
        logo: { width: 130, height: null },
        stuff: [
            { width: 110, height: null },
            { width: 90, height: null },
            { width: 50, height: null },
        ],
        rightSide: { width: 70, height: 311 },
        leftSide: { width: 89, height: 1000 },
    },
    hauntedMansion: {
        logo: { width: 560, height: 317 },
        stuff: [{ width: null, height: null }],
        rightSide: { width: 70, height: 311 },
        leftSide: { width: 75, height: 795 },
    },
    fantasyForest: {
        logo: { width: null, height: null },
        stuff: [
            { width: 170, height: 166 },
            { width: 74, height: 74 },
            { width: 68, height: 68 },
            { width: 54, height: 54 },
            { width: 134, height: 134 },
        ],
        rightSide: { width: 134, height: 134 },
        leftSide: { width: null, height: null },
    },
    library: null,
    northernLights: null,
};
