import styled from 'styled-components';
export const Main = styled.main `
  display: flex;
  align-items: center;
  flex: auto;
  position: relative;
  max-width: 100vw;
  min-height: fit-content;
  overflow: hidden;
  justify-content: center;
`;
export const Wrapper = styled.div `
  max-width: 100vw;
  min-height: 100dvh;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
