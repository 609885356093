import { isAxiosError } from 'axios';
import { useState, useEffect, useRef, useInsertionEffect } from 'react';
const useAxiosRequest = (axiosCallback, initialData, deps = []) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(initialData);
    const [error, setError] = useState(null);
    const savedCallback = useRef(axiosCallback);
    useInsertionEffect(() => {
        savedCallback.current = axiosCallback;
    }, [axiosCallback]);
    useEffect(() => {
        setLoading(true);
        const call = () => savedCallback.current();
        call().then((response) => {
            if (!response)
                return;
            if (isAxiosError(response)) {
                setError(response);
                setLoading(false);
                return;
            }
            setData(response.data);
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    return [loading, data, error];
};
export default useAxiosRequest;
