import { IconButton } from '@mui/material';
import { memo } from 'react';
import styled, { css } from 'styled-components';
const AsideLayout = styled.aside `
  margin-left: 5px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  width: 343px;
  ${({ theme }) => css `
    height: calc(100dvh - ${theme.values.header.room.height} - 15px);
  `}
  transition: height 0.2s linear;
  z-index: 10;
  align-self: flex-start;
  overflow: hidden;

  position: absolute;
  right: 0;

  ${({ theme }) => css `
    color: ${theme.palette.darkGray.main};
    background-color: ${theme.palette.white.main};
    border-top: 1px solid ${theme.palette.primary.main};
    border-left: 1px solid ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.primary.main};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  `}
`;
export const AsideSection = memo(styled.section `
  display: flex;
  ${({ theme }) => css `
    height: calc(100dvh - ${theme.values.header.room.height} - 15px);
  `}

  padding: 8px 8px 0;
  flex-direction: column;
  padding-bottom: 10px;
  overflow: hidden;

  ${({ gap }) => gap
    ? css `
          gap: ${gap};
        `
    : null}

  ${({ showGradient }) => showGradient
    ? css `
          &::before {
            content: '';
            height: 50px;
            width: 100%;
            bottom: 0;
            position: absolute;
            z-index: 20;
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
          }
        `
    : null}
`);
export const Title = styled.h6 `
  ${({ theme }) => css `
    color: inherit;
    font-weight: 600;
    margin: 0;
    font-size: ${theme.util.pxToRem(24)};
  `}
`;
export const HeadingContainer = styled.header `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HeadingButton = styled(IconButton) `
  ${({ theme }) => css `
    color: ${theme.palette.black.main};
    &:hover {
      color: ${theme.palette.primary.main};
    }
  `}
`;
export default AsideLayout;
