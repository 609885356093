import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, IconButton } from '@mui/material';
import { memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './styled';
import { VisibilityOffIcon, VisibilityIcon } from '@/shared/assets/icons';
import { userStore } from '@/shared/model/user';
import { ROUTES } from '@/shared/constants/routes';
import { headerStore } from '@/shared/model/header';
import { roomStore } from '@/shared/model/room';
const AdminControls = observer(() => {
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    const { user } = userStore;
    const { showIds } = headerStore;
    const { room } = roomStore;
    const handleChangeShowIds = useCallback(() => {
        headerStore.toggleShowIds();
    }, []);
    return !user.isAdmin || isLobby || isDashboard ? null : (_jsx(Wrapper, { children: _jsx(Tooltip, { title: showIds ? "Hide id's" : "Show id's", onClick: handleChangeShowIds, children: _jsx(IconButton, { size: 'small', color: 'white', children: showIds ? _jsx(VisibilityIcon, {}) : _jsx(VisibilityOffIcon, { color: 'error' }) }) }) }));
});
export default memo(AdminControls);
