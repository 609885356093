import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { memo } from 'react';
import { FriendInviteContainer, IconText, InfoWrapper, TextWrapper } from './styled';
import { RequestAction } from '../../model/FriendRequests/styled';
import { UserName, UserRoom } from '../../model/Friend/styled';
import { AddVideoIcon, SuccessIcon, CloseCircledIcon } from '@/shared/assets/icons';
import ProfileAvatar from '@/shared/ui/ProfileAvatar';
import { avatarPlaceholder } from '@/shared/assets/images';
const FriendInvite = ({ inviterName, zIndex, src, onAccept, onDecline, }) => (_jsxs(FriendInviteContainer, { "$zIndex": zIndex, children: [_jsx(ProfileAvatar, { src: src ?? avatarPlaceholder, alt: inviterName, height: 55, width: 55 }), _jsxs(InfoWrapper, { children: [_jsx(TextWrapper, { children: _jsxs(IconText, { children: [_jsx(AddVideoIcon, { color: 'primary' }), _jsx(UserName, { color: 'darkGray', children: inviterName })] }) }), _jsx(TextWrapper, { children: _jsx(UserRoom, { color: 'offline', children: "Invited you to join" }) })] }), _jsxs(RequestAction, { children: [_jsx(IconButton, { color: 'primary', "aria-label": 'Accept', onClick: onAccept, children: _jsx(SuccessIcon, { height: 24, width: 24 }) }), _jsx(IconButton, { color: 'inactive', "aria-label": 'Decline', onClick: onDecline, children: _jsx(CloseCircledIcon, { height: 24, width: 24 }) })] })] }));
export default memo(FriendInvite);
