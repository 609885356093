import { makeAutoObservable } from 'mobx';
class PopupStore {
    _userC = null;
    _anchorEl = null;
    _anchorOrigin = {
        horizontal: 'right',
        vertical: 'top',
    };
    _content = null;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    set anchorEl(anchorEl) {
        this._anchorEl = anchorEl;
    }
    get anchorEl() {
        return this._anchorEl;
    }
    set userC(userC) {
        this._userC = userC;
    }
    get userC() {
        return this._userC;
    }
    set content(content) {
        this._content = content;
    }
    get content() {
        return this._content;
    }
    set anchorOrigin(anchorOrigin) {
        this._anchorOrigin = anchorOrigin;
    }
    get anchorOrigin() {
        return this._anchorOrigin;
    }
    clear = () => {
        this._content = null;
        this._userC = null;
        this._anchorEl = null;
    };
}
export const popupStore = new PopupStore();
