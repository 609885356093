import { useEffect } from 'react';
import useEvent from './useEvent';
// In order for the hook to work with the store,
// you need to specify the store variables in the dependency array
const useTimeout = (callback, delay, storeDependencies = []) => {
    const savedCallback = useEvent(callback, storeDependencies);
    useEffect(() => {
        const id = setTimeout(() => savedCallback(), delay);
        return () => clearTimeout(id);
    }, [delay, savedCallback]);
};
export default useTimeout;
