import styled, { css } from 'styled-components';
export const HeaderTitle = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css `
    gap: ${theme.spacing(5)};
  `}
`;
