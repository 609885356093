import { useState, useCallback, useEffect } from 'react';
import { isAxiosError } from 'axios';
import FriendService from '@/shared/api/services/FriendService';
const useFetchChat = ({ senderId, receiverId, pageNumber, pageSize = 20, }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [messages, setMessages] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingCount, setLoadingCount] = useState(1);
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await FriendService.loadChatHistory(senderId, receiverId, pageNumber, pageSize);
        if (!response || isAxiosError(response)) {
            setError(response?.cause ?? new Error('Something went wrong'));
            setLoading(false);
            return;
        }
        const { data } = response;
        setMessages((prev) => {
            if (pageNumber === 1)
                return data.data;
            return [...prev, ...data.data];
        });
        setLoadingCount((prev) => prev + 1);
        setTotalPages(data.totalPages);
        setLoading(false);
    }, [pageNumber, pageSize, receiverId, senderId]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return [loading, error, messages, totalPages, loadingCount];
};
export default useFetchChat;
