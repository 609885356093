import styled, { css } from 'styled-components';
import InputFormField from '@/shared/ui/InputFormField';
import { SmsTrackingIcon } from '@/shared/assets/icons';
import SubmitButton from '@/shared/ui/SubmitButton';
export const Content = styled.section `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 420px;
  padding: 24px;
  align-items: center;
  justify-content: normal;
  gap: 16px;

  ${({ theme }) => css `
    ${theme.breakpoints.down('sm')} {
      max-width: 90vw;
    }
  `}
`;
export const FormWrapper = styled.form `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;
export const Title = styled.h4 `
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
`;
export const RestrictionText = styled.p `
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;
export const RestrictionWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;
export const BackWrapper = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: end;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
`;
export const Image = styled.img `
  width: 124px;
  height: 70px;
`;
export const Input = styled(InputFormField) `
  height: 42px;

  & .MuiInputBase-root {
    & .MuiInputBase-input {
      padding: 10px;
    }
  }
`;
export const Button = styled(SubmitButton) `
  height: 42px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  box-shadow: 8px 8px 0px 0px #000000;
  &:hover,
  &:focus {
    box-shadow: 8px 8px 0px 0px black;
  }
`;
export const RestrictionTextLink = styled.a `
  color: #a041ff;
  text-decoration: underline;
`;
export const SendedInfoContainer = styled.div `
  width: 100%;
  padding: 0 16px;
`;
export const SendedInfoWrapper = styled.div `
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #a041ff;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;
export const SendedInfoIcon = styled(SmsTrackingIcon) `
  height: 24px;
  width: 24px;
  color: #a041ff;
`;
export const SendedInfoTitleContainer = styled.div `
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 8px;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #a041ff;
`;
export const SendedInfoDescription = styled.div `
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #a041ff;
`;
