import styled from 'styled-components';
export const ModalContent = styled.section `
  position: relative;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: ${({ width = '576px' }) => width};
  outline: none;
`;
export const ContentHeading = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const ContentDescription = styled.div `
  max-width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .MuiTypography-root {
    text-align: center;
  }
`;
export const ContentActions = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 12px;
  width: 100%;
`;
