import { useEffect } from 'react';
import useEvent from './useEvent';
import { connectionStore } from '@/shared/model/connection';
const useInteractionHubReceive = (hubMethod, callback) => {
    const savedCallback = useEvent(callback);
    const { interactionHubConnection } = connectionStore;
    useEffect(() => {
        interactionHubConnection?.on(hubMethod, (hubMessage) => {
            savedCallback(hubMessage);
        });
        return () => {
            interactionHubConnection?.off(hubMethod);
        };
    }, [hubMethod, interactionHubConnection, savedCallback]);
};
export default useInteractionHubReceive;
