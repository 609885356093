import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useState, useMemo, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { FriendRequestsWrapper, FriendsList, RequestsCounter } from './styled';
import FriendRequests from './model/FriendRequests';
import FriendInvites from './model/FriendInvites';
import Friend from './model/Friend';
import useFilterFriends from './lib/hooks/useFilterFriends';
import Options from './components/Options';
import { NoResultWrapper } from '../ChatAside/styled';
import FriendsChat from '@/features/FriendsChat';
import AsideLayout, { AsideSection, HeadingContainer, Title, } from '@/entities/containers/AsideLayout';
import { SearchStatusIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { friendStore } from '@/shared/model/friends';
import { headerStore } from '@/shared/model/header';
import ButtonCustom from '@/shared/ui/ButtonCustom';
import SearchBar from '@/shared/ui/SearchBar';
import TextElement from '@/shared/ui/TextElement';
import AsideModal from '@/entities/containers/AsideModal';
import NotificationHub from '@/shared/api/hubs/NotificationHub';
import { HUB_METHODS } from '@/shared/constants/hub';
import { userStore } from '@/shared/model/user';
const FriendsAside = observer(() => {
    const { user } = userStore;
    const { friends, newFriends } = friendStore;
    const { friendsListOpened, friendChatOpened, friendRequestsOpened } = headerStore;
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const [searchValue, setSearchValue] = useState('');
    const handleChange = useCallback((event) => {
        setSearchValue(event.target.value);
    }, []);
    const { myFriends, onlineFriends, searchedFriends, requests } = useFilterFriends(searchValue);
    const handleOpenFriendRequests = useCallback(() => {
        headerStore.friendRequestsOpened = true;
    }, []);
    const handleCloseFriendRequests = useCallback(() => {
        headerStore.friendRequestsOpened = false;
    }, []);
    const handleOpenChat = useCallback((receiverId) => async () => {
        friendStore.activeFriendChatId = receiverId;
        const friendEntity = friends.find((friend) => friend.id === receiverId);
        if (friendEntity?.lastChatMessage?.unread) {
            headerStore.cleanUnreadMessageCount(receiverId);
            if (!headerStore.unreadMessagesCount) {
                headerStore.chatMessageReceived = false;
            }
            const clearedFriends = friends.filter((friend) => friend.id !== receiverId);
            friendStore.friends = [
                ...clearedFriends,
                { ...friendEntity, lastChatMessage: { ...friendEntity.lastChatMessage, unread: false } },
            ];
        }
        headerStore.friendChatOpened = true;
        friendStore.newFriends = [...newFriends.filter((friend) => friend.id !== receiverId)];
        await NotificationHub.sendMessage(HUB_METHODS.notification.send.readFriendChat, {
            id: user.id,
            friendId: receiverId,
        });
    }, [friends, newFriends, user.id]);
    const handleCloseChat = useCallback(() => {
        friendStore.activeChatHistory = [];
        friendStore.activeFriendChatId = 0;
        headerStore.friendChatOpened = false;
    }, []);
    const section = useMemo(() => {
        if (friendChatOpened) {
            return _jsx(FriendsChat, { onClose: handleCloseChat });
        }
        if (friendRequestsOpened) {
            return _jsx(FriendRequests, { onClose: handleCloseFriendRequests, friendsRequests: requests });
        }
        return null;
    }, [
        friendRequestsOpened,
        requests,
        handleCloseChat,
        handleCloseFriendRequests,
        friendChatOpened,
    ]);
    return (_jsx(AsideModal, { open: friendsListOpened, children: _jsx(AsideLayout, { children: section ?? (_jsxs(AsideSection, { gap: '8px', children: [_jsxs(HeadingContainer, { children: [_jsx(Title, { children: "Friends" }), _jsx(Options, {})] }), _jsx(FriendInvites, {}), _jsx(SearchBar, { value: searchValue, onChange: handleChange }), requests.length ? (_jsxs(FriendRequestsWrapper, { children: [_jsx(ButtonCustom, { maxWidth: 150, fontWeight: 500, fontSize: 16, maxHeight: 30, onClick: handleOpenFriendRequests, children: "Friend Requests" }), _jsx(RequestsCounter, { children: requests.length })] })) : null, searchValue ? null : (_jsxs(TextElement, { fontSize: 18, paddingLeft: '10px', paddingTop: '3px', children: ["General", ' ', _jsxs(TextElement, { component: 'span', fontSize: 16, children: ["(", onlineFriends.length, "/", myFriends.length, ")"] })] })), searchedFriends.length ? (_jsx(FriendsList, { "$isLobby": isLobby, children: searchedFriends.map(({ id: friendId, lastOnlinePlace, onlineStatus, name, profilePicture, accepted, deleted, unread, }) => {
                            if (!accepted)
                                return null;
                            if (deleted)
                                return null;
                            return (_jsx(Friend, { onOpen: handleOpenChat(friendId), online: onlineStatus, lastOnlinePlace: lastOnlinePlace, name: name, friendId: friendId, profilePicture: profilePicture, unread: unread }, friendId));
                        }) })) : null, !searchedFriends.length && searchValue ? (_jsxs(NoResultWrapper, { children: [_jsx(SearchStatusIcon, {}), _jsx(TextElement, { fontSize: 16, textAlign: 'center', children: `We couldn't find any matches for “${searchValue}”. Try checking for typos or
                    using complete words.` })] })) : null] })) }) }));
});
export default memo(FriendsAside);
