import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet, Navigate } from 'react-router-dom';
import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import useConnectToNotifications from './lib/hooks/useConnectToNotifications';
import useVerifyUser from './lib/hooks/useVerifyUser';
import { ROUTES } from '@/shared/constants/routes';
const ProtectedRoute = observer(() => {
    const [verifying, verified] = useVerifyUser();
    useConnectToNotifications(verified);
    if (verifying) {
        return _jsx(CircularProgress, { color: 'primary' });
    }
    if (!verified) {
        return _jsx(Navigate, { to: ROUTES.home.path, replace: true });
    }
    return _jsx(Outlet, {});
});
export default memo(ProtectedRoute);
