import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useId } from 'react';
import { IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { UncheckedRequests } from './styled';
import useFriendsNotificationsCounter from '../../lib/hooks/useFriendsNotificationCounter';
import { SocialsFilledIcon, SocialsIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { headerStore } from '@/shared/model/header';
import CustomTooltip from '@/shared/ui/CustomTooltip';
const FriendsButton = observer(() => {
    const { friendsListOpened, friendChatOpened } = headerStore;
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const id = useId();
    const handleClick = useCallback(() => {
        if (friendsListOpened && friendChatOpened) {
            headerStore.friendChatOpened = false;
            return;
        }
        headerStore.friendsListOpened = !friendsListOpened;
    }, [friendsListOpened, friendChatOpened]);
    const [isMax, counter] = useFriendsNotificationsCounter();
    const color = isLobby ? 'black' : 'white';
    const open = friendsListOpened && !friendChatOpened;
    return (_jsx(CustomTooltip, { placement: 'bottom', title: open ? 'Close friends list' : 'Open friends list', children: _jsxs(IconButton, { id: 'button1', color: open ? 'primary' : color, size: 'small', onClick: handleClick, children: [open ? _jsx(SocialsFilledIcon, {}) : _jsx(SocialsIcon, {}), counter && !friendsListOpened ? (_jsx(UncheckedRequests, { id: id, isMax: isMax, "aria-label": 'You have unchecked requests', children: counter })) : null] }) }));
});
export default memo(FriendsButton);
