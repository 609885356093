import { useMemo } from 'react';
import { friendStore } from '@/shared/model/friends';
const useFilterFriends = (searchValue) => {
    const { friends } = friendStore;
    const myFriends = useMemo(() => friends.filter((friend) => friend.accepted && !friend.deleted), [friends]);
    const onlineFriends = useMemo(() => friends.filter((friend) => friend.accepted && friend.onlineStatus), [friends]);
    const requests = useMemo(() => friends.filter((friend) => !friend.accepted), [friends]);
    const sortedByOnline = useMemo(() => [...myFriends].sort((a, _) => (a.onlineStatus ? -1 : 1)), [myFriends]);
    const searchedFriends = useMemo(() => searchValue
        ? sortedByOnline.filter(({ name }) => name.toLocaleLowerCase().startsWith(searchValue.toLocaleLowerCase()))
        : sortedByOnline, [sortedByOnline, searchValue]);
    const myFriendsEmails = useMemo(() => friends.map((friend) => friend.email), [friends]);
    return { myFriends, onlineFriends, requests, sortedByOnline, searchedFriends, myFriendsEmails };
};
export default useFilterFriends;
