import { useEffect, useState } from 'react';
import _debounce from 'lodash.debounce';
const useKeyboardOpenStatus = (keyboardThreshold = 150) => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (!window.visualViewport)
                return;
            setIsKeyboardOpen(window.screen.height - keyboardThreshold > window.visualViewport.height);
        };
        const handleResizeDebounced = _debounce(handleResize, 100);
        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', handleResizeDebounced);
        }
        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', handleResizeDebounced);
            }
        };
    }, [keyboardThreshold]);
    return isKeyboardOpen;
};
export default useKeyboardOpenStatus;
