import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Slider } from '@mui/material';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { VolumeIcon, VolumeSliderWrapper, VolumeTooltip, VolumeTooltipPopper } from './styled';
import { userPreferencesStore } from '@/shared/model/user-preferences';
import { useClickAway } from '@/shared/lib/hooks';
import { ROLES } from '@/shared/constants/roles';
import { userStore } from '@/shared/model/user';
import { roomStore } from '@/shared/model/room';
const BACKGROUND_MUSIC_MIN_VOLUME = 0;
const BACKGROUND_MUSIC_MAX_VOLUME = 0.05; // audio volume max = 1, but to better visual and UX selected to this value
const BACKGROUND_MUSIC_VOLUME_STEP = 0.001;
const PERFORMER_VIDEO_MIN_VOLUME = 0;
const PERFORMER_VIDEO_MAX_VOLUME = 100;
const PERFORMER_VIDEO_VOLUME_STEP = 1;
export const RoomVolumeControl = observer(() => {
    const { roomBackgroundMusicVolume, stageVolume } = userPreferencesStore;
    const [isOpen, setIsOpen] = useState(false);
    const { user } = userStore;
    const { room: { isSpeedDating }, } = roomStore;
    const isUserPerformer = user.role === ROLES.performer;
    const handleBackgroundMusicVolumeChange = useCallback((_, newValue) => {
        userPreferencesStore.roomBackgroundMusicVolume = newValue;
    }, []);
    const handleStageVolumeChange = useCallback((_, newValue) => {
        userPreferencesStore.stageVolume = newValue;
    }, []);
    const handleOpenToggle = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);
    const ref = useClickAway(handleOpenToggle);
    return (_jsx(VolumeTooltip, { arrow: true, open: isOpen, title: _jsxs(VolumeSliderWrapper, { ref: ref, children: ["Background Music:", _jsx(Slider, { "aria-label": 'Volume', value: roomBackgroundMusicVolume, size: 'small', min: BACKGROUND_MUSIC_MIN_VOLUME, max: BACKGROUND_MUSIC_MAX_VOLUME, step: BACKGROUND_MUSIC_VOLUME_STEP, onChange: handleBackgroundMusicVolumeChange }), !isUserPerformer && !isSpeedDating && (_jsxs(_Fragment, { children: ["Performer Volume:", _jsx(Slider, { "aria-label": 'Volume', value: stageVolume, size: 'small', min: PERFORMER_VIDEO_MIN_VOLUME, max: PERFORMER_VIDEO_MAX_VOLUME, step: PERFORMER_VIDEO_VOLUME_STEP, onChange: handleStageVolumeChange })] }))] }), PopperComponent: VolumeTooltipPopper, children: _jsx(IconButton, { onClick: handleOpenToggle, size: 'small', color: 'white', children: _jsx(VolumeIcon, {}) }) }));
});
