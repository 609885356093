import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { LobbyIcon } from '@/shared/assets/icons';
import { ROLES } from '@/shared/constants/roles';
import { ROUTES } from '@/shared/constants/routes';
import { useRoomDisconnect } from '@/shared/lib/hooks';
import { headerStore } from '@/shared/model/header';
import { modalStore } from '@/shared/model/modal';
import { roomStore } from '@/shared/model/room';
import { userStore } from '@/shared/model/user';
import ChangeTable from '@/entities/notifications/ChangeTable';
import CustomTooltip from '@/shared/ui/CustomTooltip';
export const LeaveRoomControl = observer(() => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    const { room: { isSpeedDating }, } = roomStore;
    const { user: { role }, } = userStore;
    const { showControls } = headerStore;
    const handleSubmit = useCallback(async () => {
        await disconnect();
        const feedbackState = role !== ROLES.performer
            ? {
                state: {
                    withFeedback: true,
                },
            }
            : undefined;
        navigate(role !== ROLES.performer ? ROUTES.rooms.path : ROUTES.speakerDashboard.path, feedbackState);
    }, [disconnect, navigate, role]);
    const handleDisconnect = useCallback(() => {
        if (showControls) {
            if (isSpeedDating) {
                modalStore.open({
                    content: (_jsx(ChangeTable, { title: 'Leave room?', confirmLabel: 'Yes, leave room', declineLabel: 'No, stay', buttonsFullWidth: true, onSubmit: handleSubmit, message: 'Are you sure that you want to leave this room?' })),
                });
            }
            else {
                modalStore.open({
                    content: (_jsx(ChangeTable, { title: 'Leave room?', confirmLabel: 'Yes, leave room', buttonsFullWidth: true, onSubmit: handleSubmit, message: 'Leaving the room means losing all sent and received interactions with other users' })),
                });
            }
            return;
        }
        disconnect();
        navigate(role !== ROLES.performer ? ROUTES.rooms.path : ROUTES.speakerDashboard.path);
    }, [showControls, disconnect, navigate, role, isSpeedDating, handleSubmit]);
    return (_jsx(CustomTooltip, { title: 'Return to Lobby', placement: 'bottom', children: _jsx(IconButton, { color: 'white', onClick: handleDisconnect, "aria-label": 'Return to lobby', children: _jsx(LobbyIcon, { width: 27, height: 18 }) }) }));
});
