import { useEffect, useState } from 'react';
const useIsElementOnTheScreen = (elementRef) => {
    const [isOnTheScreen, setIsOnTheScreen] = useState(false);
    useEffect(() => {
        const wrapper = elementRef.current;
        const observer = new IntersectionObserver(([entry]) => {
            setIsOnTheScreen(entry.isIntersecting);
        });
        observer.observe(wrapper);
        return () => observer.unobserve(wrapper);
    }, [elementRef]);
    return isOnTheScreen;
};
export default useIsElementOnTheScreen;
