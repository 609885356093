import styled, { css } from 'styled-components';
import { Avatar, Button, TextField } from '@mui/material';
export const ProfileFormWrapper = styled.form `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 35px 144px 0 143px;
`;
export const ExtendedTextField = styled(TextField) `
  position: relative;
  .MuiInputBase-root {
    height: 48px;
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -1.2rem;
  }
`;
export const ProfilePicture = styled(Avatar) `
  width: 100%;
  height: 100%;
  border-radius: 7px;
`;
export const ProfilePictureWrapper = styled.div `
  display: flex;
  position: relative;
  border-radius: 8px;
  width: 105px;
  height: 105px;

  border: 1px solid #8f8f8f;
`;
export const ProfileFormHeader = styled.div `
  display: flex;
  width: 100%;
  margin-bottom: 30px;
`;
export const ProfileTitle = styled.p `
  align-self: flex-end;

  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #8f8f8f;

  padding-left: 14px;
`;
export const EditButton = styled(Button) `
  width: 92px;
  height: 40px;

  color: #ffffff;

  ${({ theme }) => css `
    background-color: ${theme.palette.primary.main};
  `}

  border: 1px solid #ffffff;
  border-radius: 6px;

  float: right;
  margin-left: auto;

  &:hover {
    ${({ theme }) => css `
      color: ${theme.palette.primary.main};
    `}
  }
`;
export const FileInputUploader = styled.input `
  display: none;
`;
