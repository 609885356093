import { useRef, useEffect } from 'react';
import useKeyboardOpenStatus from './useKeyboardOpenStatus';
const useScrollToInput = () => {
    const inputRef = useRef(null);
    const isKeyboardOpen = useKeyboardOpenStatus();
    useEffect(() => {
        // Scroll to the bottom when the mobile keyboard opens
        if (isKeyboardOpen && inputRef.current) {
            inputRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'end' });
        }
    }, [isKeyboardOpen]);
    return inputRef;
};
export default useScrollToInput;
