import { Button } from '@mui/material';
import styled, { css } from 'styled-components';
export const ModalContent = styled.section `
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 380px;
  padding-bottom: 20px;
`;
export const CloseButtonWrapper = styled.div `
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
  padding-right: 5px;
  padding-top: 5px;
`;
export const NotificationContent = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
export const OkButton = styled(Button) `
  box-shadow: 0px 2px 15px rgba(52, 52, 176, 0.3);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  width: 127px;
  height: 46px;
  ${({ theme }) => css `
    border-color: ${theme.palette.white.main};
    color: ${theme.palette.primary.main};
    font-size: ${theme.util.pxToRem(14)};

    &:hover {
      border-color: ${theme.palette.white.main};
    }
  `}
`;
export const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
`;
