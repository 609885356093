const get = (key, parse = true) => {
    if (!parse)
        return localStorage.getItem(key);
    const item = localStorage.getItem(key);
    if (item)
        return JSON.parse(item);
    return null;
};
const set = (key, value) => {
    try {
        const stringifiedValue = typeof value === 'string' ? value : JSON.stringify(value);
        localStorage.setItem(key, stringifiedValue);
        return true;
    }
    catch (error) {
        if (!(error instanceof Error))
            return false;
        const alertMsg = 'Error!';
        alert(alertMsg);
        console.error(error.message);
        return false;
    }
};
const remove = (key) => localStorage.removeItem(key);
const StorageService = {
    remove,
    set,
    get,
};
export default StorageService;
