import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from 'react';
import { FriendChatContainer, UserContainer, ProfilePicture, OnlineStatus, TextWrapper, UserName, UserMessage, ChatInfo, MessageCounter, Time, } from '../../styled';
import { useInterval } from '@/shared/lib/hooks';
import DateService from '@/shared/lib/utils/date';
const FriendChat = ({ profilePicture, name, id, deleted, online, onOpen, lastMessage, isMineLastMessage, }) => {
    const [receiveTime, setReceiveTime] = useState(DateService.convertOffsetToTimeDifference(lastMessage?.sentTime));
    const message = useMemo(() => {
        if (lastMessage) {
            return isMineLastMessage ? `you: ${lastMessage.data}` : `${lastMessage.data}`;
        }
        return null;
    }, [isMineLastMessage, lastMessage]);
    useEffect(() => {
        setReceiveTime(DateService.convertOffsetToTimeDifference(lastMessage?.sentTime));
    }, [lastMessage]);
    useInterval(() => {
        setReceiveTime(DateService.convertOffsetToTimeDifference(lastMessage?.sentTime));
    }, 10000);
    return (_jsxs(FriendChatContainer, { onClick: onOpen, children: [_jsxs(UserContainer, { children: [_jsx(ProfilePicture, { src: profilePicture, alt: name }), deleted ? null : (_jsx(OnlineStatus, { status: online ? 'online' : 'offline', "aria-label": online ? `${name} is online` : `${name} is offline` }))] }), _jsxs(TextWrapper, { children: [_jsx(UserName, { color: online ? 'darkGray' : 'offline', children: name }), lastMessage ? (_jsx(UserMessage, { isUnread: lastMessage.unread, children: lastMessage.data })) : (_jsx(UserMessage, { children: message }))] }), deleted ? null : (_jsxs(ChatInfo, { children: [lastMessage?.unread ? (_jsx(MessageCounter, { "aria-label": `You have unread message from ${name}` })) : null, _jsx(Time, { children: receiveTime })] }))] }, id));
};
export default memo(FriendChat);
