import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { NotificationHubStatusCode } from '@/shared/constants/hub';
import TextElement from '@/shared/ui/TextElement';
const getStatusMessage = (status) => {
    switch (status) {
        case NotificationHubStatusCode.RoomFull:
            return (_jsxs(_Fragment, { children: [_jsx(TextElement, { fontWeight: 600, fontSize: 18, textAlign: 'center', marginBottom: '10px', children: "Oops!" }), _jsx(TextElement, { fontSize: 18, textAlign: 'center', children: "Seems like your friends room is already full." })] }));
            break;
        case NotificationHubStatusCode.InviterLeft:
            return (_jsxs(_Fragment, { children: [_jsx(TextElement, { fontWeight: 600, fontSize: 18, textAlign: 'center', marginBottom: '10px', children: "Oops!" }), _jsx(TextElement, { fontSize: 18, textAlign: 'center', children: "It seems like your friend has already returned to the lobby." }), _jsx(TextElement, { fontSize: 18, textAlign: 'center', children: "They might be taking a break or eagerly waiting for your invitation. Don't keep them waiting!" })] }));
        case NotificationHubStatusCode.InviterOffline:
            return (_jsxs(_Fragment, { children: [_jsx(TextElement, { fontWeight: 600, fontSize: 18, textAlign: 'center', marginBottom: '10px', children: "Oops!" }), _jsx(TextElement, { fontSize: 18, textAlign: 'center', children: "It seems like your friend is no longer online." }), _jsx(TextElement, { fontSize: 18, textAlign: 'center', children: "Don't worry, though! Next time, don't be shy to invite them again." })] }));
        default:
            return 'Something is wrong';
    }
};
export default getStatusMessage;
