import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { CloseButtonWrapper } from './styled';
import { modalStore } from '@/shared/model/modal';
import { CloseIcon } from '@/shared/assets/icons';
export const CloseButton = () => {
    const handleClose = useCallback(() => {
        modalStore.close();
    }, []);
    return (_jsx(CloseButtonWrapper, { children: _jsx(IconButton, { size: 'small', "aria-label": 'Close notification', onClick: handleClose, children: _jsx(CloseIcon, {}) }) }));
};
