import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useRef, useLayoutEffect, useCallback, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import Chat from '@/entities/Chat';
import { ROUTES } from '@/shared/constants/routes';
import { userStore } from '@/shared/model/user';
import { isKeyofObject } from '@/shared/lib/utils/typeGuards';
import { ChatContentPaper, ChatContent, MessageSender, MessageText, Time, Message, } from '@/entities/Chat/styled';
import TableChatHub from '@/shared/api/hubs/TableChatHub';
import { HUB_METHODS } from '@/shared/constants/hub';
import { tablePlaceholder } from '@/shared/assets/images';
const TableChat = observer(({ connected, onClose, chatHistory, usernameColorScheme }) => {
    const { user, userTable } = userStore;
    const chatContentRef = useRef(null);
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const handleSend = useCallback(async (message) => {
        await TableChatHub.sendMessage(HUB_METHODS.table.send.messageToGroup, { message });
    }, []);
    useLayoutEffect(() => {
        if (userTable?.tableName === 'Bar') {
            onClose();
        }
    }, [onClose, userTable]);
    useLayoutEffect(() => {
        chatContentRef.current?.scrollTo(0, chatContentRef.current?.scrollHeight);
    }, [chatHistory.length]);
    return (_jsx(Chat, { connected: connected, picture: tablePlaceholder, title: 'Table Chat', onSend: handleSend, onClose: onClose, addPictureBorder: true, removeMoreActions: true, children: _jsx(ChatContentPaper, { ref: chatContentRef, elevation: 0, "$isLobby": isLobby, children: _jsx(ChatContent, { children: chatHistory.map(({ id, username, message, sentTime }) => {
                    const mine = username === user.userName;
                    const time = format(new Date(sentTime), 'hh:mm');
                    const color = isKeyofObject(username, usernameColorScheme)
                        ? usernameColorScheme[username]
                        : 'grey';
                    return (_jsxs(Message, { id: id, mine: mine, children: [mine ? null : _jsx(MessageSender, { color: color, children: username }), _jsx(MessageText, { id: id, children: message }), _jsx(Time, { id: id, dateTime: sentTime, children: time })] }, id));
                }) }) }) }));
});
export default memo(TableChat, isEqual);
