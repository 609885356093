import { useState, useEffect } from 'react';
import { logos, stuffs, leftSide, rightSide, coords, size } from '../../constants';
import { roomStore } from '@/shared/model/room';
const useRoomTheme = () => {
    const [roomTheme, setRoomTheme] = useState({
        logo: '',
        stuff: '',
        rightSide: '',
        leftSide: '',
        coords: null,
        size: null,
    });
    const { room } = roomStore;
    const currentRoomTheme = room.theme;
    useEffect(() => {
        const newTheme = {
            logo: logos[currentRoomTheme] || null,
            stuff: stuffs[currentRoomTheme] || null,
            rightSide: rightSide[currentRoomTheme] || null,
            leftSide: leftSide[currentRoomTheme] || null,
            coords: coords[currentRoomTheme] ?? null,
            size: size[currentRoomTheme] ?? null,
        };
        setRoomTheme(newTheme);
    }, [currentRoomTheme]);
    return roomTheme;
};
export default useRoomTheme;
