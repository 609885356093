import { jsx as _jsx } from "react/jsx-runtime";
import { Slide } from '@mui/material';
import { memo } from 'react';
import { BackdropStyled, ModalContainer } from './styled';
import { headerStore } from '@/shared/model/header';
const AsideModal = ({ open, children }) => {
    const handleCloseAside = () => {
        headerStore.friendChatOpened = false;
        headerStore.chatListOpened = false;
        headerStore.closeAllChats();
    };
    return (_jsx(ModalContainer, { open: open, onClose: handleCloseAside, closeAfterTransition: true, slots: {
            backdrop: BackdropStyled,
        }, children: _jsx(Slide, { in: open, direction: 'left', mountOnEnter: true, children: children }) }));
};
export default memo(AsideModal);
