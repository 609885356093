import { object, string } from 'zod';
export const registerScheme = object({
    email: string({
        required_error: 'Required field',
    })
        .min(1, 'Required field')
        .email('Email is not valid')
        .transform((value) => value.trim()),
});
export const defaultValues = {
    email: '',
};
