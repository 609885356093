import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { observer } from 'mobx-react-lite';
import { isAxiosError } from 'axios';
import { Link } from '@mui/material';
import { defaultDeleteValues, deleteScheme } from './validation';
import { DeleteFormWrapper, DeleteFormHead, BackButton, Title, Description, DeleteFormBody, InputWithTitle, DeleteFormButtons, ResetButton, } from './styled';
import UserService from '@/shared/api/services/UserService';
import { ArrowBackIcon, LockIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { useSignOut } from '@/shared/lib/hooks';
import { userStore } from '@/shared/model/user';
import InputFormField from '@/shared/ui/InputFormField';
import SubmitButton from '@/shared/ui/SubmitButton';
import { ROLES } from '@/shared/constants/roles';
const DeleteForm = observer(({ setSelectedPage, onEnableClose, onDisableClose }) => {
    const { user } = userStore;
    const [loading, setLoading] = useState(false);
    const [errorHelper, setErrorHelper] = useState(null);
    const loaderId = useId();
    const { signOut } = useSignOut();
    const { handleSubmit, reset, control, formState: { errors, isValid }, } = useForm({
        defaultValues: defaultDeleteValues,
        resolver: zodResolver(deleteScheme),
    });
    const onCancel = () => {
        reset();
        setSelectedPage('Profile & Preferences');
    };
    const onSubmit = useCallback(async ({ password }) => {
        if (!isValid)
            return;
        setLoading(true);
        onDisableClose();
        const response = await UserService.deleteUser(user.id, password);
        if (response && !isAxiosError(response)) {
            signOut();
        }
        else {
            setErrorHelper('Wrong password!');
        }
        setLoading(false);
        onEnableClose();
    }, [user.id, isValid, onDisableClose, onEnableClose, signOut]);
    return (_jsxs(DeleteFormWrapper, { onSubmit: handleSubmit(onSubmit), "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, children: [_jsxs(DeleteFormHead, { children: [_jsxs(BackButton, { onClick: onCancel, children: [_jsx(ArrowBackIcon, {}), " Back"] }), _jsx(Title, { children: "Delete account?" }), _jsx(Description, { children: "Are you sure you want to delete your account? This action is irreversible and will permanently delete your profile, messages, and all associated data." })] }), _jsxs(DeleteFormBody, { children: [_jsxs(InputWithTitle, { children: [_jsx(Description, { children: "Enter your password to confirm" }), _jsx(Controller, { name: 'password', control: control, render: ({ field: { ref, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, type: 'password', Icon: LockIcon, fullWidth: true, padding: 0, error: !!errorHelper ?? !!errors.password, autoComplete: 'current-password', placeholder: 'Password', helperText: errorHelper ?? errors.password?.message })) })] }), _jsx(Link, { href: user.role === ROLES.user
                            ? ROUTES.forgotPassword.path
                            : ROUTES.forgotPassword.performer.path, color: 'primary', underline: 'hover', target: '_blank', children: "Forgot Password?" })] }), _jsxs(DeleteFormButtons, { children: [_jsx(SubmitButton, { width: 144, height: 48, loaderId: loaderId, loading: loading, "aria-describedby": loading ? loaderId : undefined, "aria-busy": loading, color: 'secondary', children: "Delete account" }), _jsx(ResetButton, { color: 'secondary', type: 'reset', onClick: onCancel, children: "Cancel" })] })] }));
});
export default memo(DeleteForm);
