import axiosInstance from '../http';
import { API } from '@/shared/constants/api';
import ServiceBuilder from '@/shared/lib/utils/service';
const getRoomDetails = async (roomId) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(API.roomDetails, {
        params: { roomId },
    });
    return response;
});
const getRoomTables = async (roomId) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(API.roomTables, {
        params: { roomId },
    });
    return response;
});
const getAllRooms = async (params) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(API.rooms, {
        params,
    });
    return response;
});
const RoomService = {
    getRoomDetails,
    getRoomTables,
    getAllRooms,
};
export default RoomService;
