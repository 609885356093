import styled from 'styled-components';
export const ModalContent = styled.section `
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 380px;
  padding: 32px;

  max-width: ${({ width = '455px' }) => width};

  gap: 12px;
  justify-content: center;
  align-items: center;

  & button {
    margin-top: 12px;
  }
`;
