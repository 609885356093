import styled from 'styled-components';
export const ModalContent = styled.section `
  position: relative;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  min-width: ${({ width = '576' }) => width}px;
  outline: none;
`;
export const ModalContentHeading = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const ModalContentDescription = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & .MuiTypography-root {
    text-align: center;
  }
`;
export const ModalContentActions = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 12px;
  width: 100%;
`;
export const CloseButtonWrapper = styled.div `
  position: absolute;
  display: flex;
  justify-content: right;
  align-items: center;
  right: 16px;
  top: 16px;

  svg {
    color: #aba7af;
    width: 16px;
    height: 16px;

    path {
      stroke-width: 1px;
    }
  }
`;
